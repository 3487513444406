.product__option {
    margin: 2.4rem 0 0;
    position: relative;

    .product-options__tooltip {
        @include tooltip(left);
        width: 16.5rem;
        text-align: center;
    }

    .product__configurable-option {
        margin: 0 0 2.4rem;

        dl {
            dt {
                display: flex;
                align-items: center;
                gap: $grid__padding;
                font-size: 1.8rem;
                line-height: 2.8rem;
                font-weight: 700;

                > button, > span, ._selected-color {
                    font-weight: 400;
                    color: color(Gray, Gray-700);
                    font-size: 1.6rem;

                    &:is(button) {
                        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='#{color(Gray, Gray-700)}' viewBox='0 0 512 512'%3E%3C!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--%3E%3Cpath d='M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM216 336l24 0 0-64-24 0c-13.3 0-24-10.7-24-24s10.7-24 24-24l48 0c13.3 0 24 10.7 24 24l0 88 8 0c13.3 0 24 10.7 24 24s-10.7 24-24 24l-80 0c-13.3 0-24-10.7-24-24s10.7-24 24-24zm40-208a32 32 0 1 1 0 64 32 32 0 1 1 0-64z'/%3E%3C/svg%3E");
                        background-repeat: no-repeat;
                        background-position: right center;
                        text-decoration: underline;
                        font-size: 1.4rem;
                        line-height: 1.8rem;
                        padding-right: 2.2rem;
                        background-size: 1.5rem;
                    }
                }

                > div {
                    display: flex;
                    align-items: center;
                    gap: .8rem;
                }

                .blaze-controls {
                    margin-left: auto;
                    font-weight: 400;
                }
            }

            dd {
                margin: 1.2rem 0 0;

                &._image {
                    position: relative;

                    &::after {
                        content: '';
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        right: 0;
                        background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
                        width: 3rem;
                    }

                    .blaze-slider.--placeholder {
                        --slides-to-show: 4.4;
                        --slide-gap: .8rem;

                        @include tablet-only() {
                            --slides-to-show: 5.4;
                        }

                        @include breakpoint(medium) {
                            --slides-to-show: 7.4;
                        }
                    }

                    .slider__slide {
                        > a, > span {
                            @include transition();
                            @include border-radius(.2rem);
                            @include background-image-logo();
                            position: relative;
                            padding: .6rem;
                            display: flex;
                            overflow: hidden;
                            border: .1rem solid color(Gray, Gray-150);
                            aspect-ratio: 1;

                            &._active {
                                border-color: color(Base, Black);
                            }

                            @include hover() {
                                border-color: color(Base, Black);
                            }

                            img {
                                @media screen and (min-width: 768px) {
                                    height: 100%;
                                }
                                width: 100%;
                                object-fit: scale-down;
                            }

                            > span {
                                position: absolute;
                                top: 0;
                                left: 0;
                                right: 0;
                                bottom: 0;
                                background-color: rgba(255, 255, 255, .8);
                            }
                        }
                    }

                    .--extra-colors {
                        position: absolute;
                        color: color(Gray, Gray-700);
                        font-size: 1.2rem;
                        font-weight: 600;
                        top: calc(100% - -.5rem);
                    }
                }

                ._size {
                    display: flex;
                    flex-wrap: wrap;
                    gap: .8rem;
                    align-items: center;
                    position: relative;
                }

                .--loading {
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .--spinner {
                        margin: 0;
                    }
                }

                label {
                    @include clickable-label-item();
                    min-width: 15%;

                    @include breakpoint(medium) {
                        min-width: 10%;
                    }

                    @include tablet-only() {
                        min-width: 15%;
                    }

                    > span {
                        white-space: nowrap;
                    }

                    &.--placeholder {
                        background-color: color(Base, PlaceHolder);
                        pointer-events: none;

                        > span {
                            opacity: 0;
                        }
                    }

                    ._stock-notification {
                        position: absolute;
                        top: -.5rem;
                        right: -.5rem;
                        z-index: 1;
                        width: 2rem;
                        height: 2rem;
                        cursor: pointer;
                        background-color: color(Base, White);
                        background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='11px' height='12px' viewBox='0 0 11 12' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EShape%3C/title%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cpath d='M10.92,7.98 C10.92,8.67542027 10.3544629,9.24 9.65792243,9.24 L1.26207757,9.24 C0.565093137,9.24 0,8.67618083 0,7.98 C0,7.28476308 0.56461944,6.72 1.26043825,6.72 C1.25962089,6.72 1.26,4.19984237 1.26,4.19984237 C1.26,1.87973033 3.14011517,0 5.46,0 C7.77990041,0 9.66,1.88017891 9.66,4.19984237 L9.66,6.71963391 C10.3543424,6.72 10.92,7.28454395 10.92,7.98 Z M9.65956175,7.56 C9.19542599,7.56 8.82,7.18475629 8.82,6.71963391 L8.82,4.19984237 C8.82,2.34409016 7.31597328,0.84 5.46,0.84 C3.60399587,0.84 2.1,2.34368801 2.1,4.19984237 L2.1,6.71963391 C2.1,7.18342433 1.7236557,7.56 1.26043825,7.56 C1.02858647,7.56 0.84,7.74863445 0.84,7.98 C0.84,8.2118367 1.02858846,8.4 1.26207757,8.4 L9.65792243,8.4 C9.89086201,8.4 10.08,8.2111822 10.08,7.98 C10.08,7.74883412 9.89079336,7.56 9.65956175,7.56 Z M3.57,9.87 L4.41,9.87 C4.41,10.449899 4.88010102,10.92 5.46,10.92 C6.03989898,10.92 6.51,10.449899 6.51,9.87 L7.35,9.87 C7.35,10.9138182 6.50381817,11.76 5.46,11.76 C4.41618183,11.76 3.57,10.9138182 3.57,9.87 Z' id='Shape' fill='%23333333' fill-rule='nonzero'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
                        background-repeat: no-repeat;
                        background-position: center;
                        box-shadow: 0 .1rem .2rem 0 rgba(0, 0, 0, .5);
                        border-radius: 50%;
                    }
                }
            }
        }

        .stock__notification {
            display: block;
            margin: .5rem 0 0;
            font-size: 1.3rem;
            color: #fc0f40;
        }
    }
}

.out-of-stock__related-products {
    margin: 2.4rem 0 0;

    h4 {
        font-size: 1.5rem;
        font-weight: 700;
        margin: 0 0 1.6rem;
    }

    .--products {
        display: flex;
        flex-wrap: wrap;
        gap: .8rem;

        a {
            @include transition();
            @include border-radius(.2rem);
            @include background-image-logo();
            position: relative;
            padding: .6rem;
            display: flex;
            overflow: hidden;
            border: .1rem solid color(Gray, Gray-150);
            aspect-ratio: 1;

            @include hover() {
                border-color: color(Base, Black);
            }
        }
    }
}
