.page-builder__blog-categories {
    background-color: color(Gray, Gray-200);
    padding: $grid__gutter 0;

    h2 {
        @include title-after();
        text-align: center;
    }

    .blog__categories {
        margin: $grid__gutter 0;
        display: flex;
        flex-wrap: wrap;
        gap: .8rem;
        justify-content: center;
    }
}

.page-builder__blog-items {
    .featured__post {
        > a, > div {
            display: block;
            height: 65vh;
            min-height: 40rem;
            position: relative;

            .content__container {
                background: linear-gradient(180deg, rgba(0, 0, 0, .2) 0%, rgba(0, 0, 0, .4) 60%, rgba(0, 0, 0, 1) 100%);
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                z-index: 1;

                > .grid-container {
                    height: 100%;
                }

                .--content {
                    display: flex;
                    flex-flow: column;
                    height: 100%;
                    justify-content: flex-end;
                    padding: $grid__gutter 0;
                    gap: $grid__padding;

                    @include breakpoint(medium) {
                        max-width: 45%;
                    }

                    h2 {
                        @include title-after();
                        @include text-shadow();
                        color: color(Base, White);
                        font-size: 3.8rem;
                    }

                    p {
                        @include text-shadow();
                        font-weight: 500;
                        color: color(Base, White);
                        font-size: 1.8rem;
                        line-height: 1.6;
                    }

                    > span {
                        margin-right: auto;
                    }
                }
            }

            picture {
                height: 65vh;
                min-height: 40rem;
                display: flex;

                img {
                    object-fit: cover;
                    object-position: center;
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }

    .blog__posts {
        margin: $grid__gutter 0;

        .--item {
            @include border-radius(1rem);
            overflow: hidden;
            margin-bottom: $grid__gutter;
            height: calc(100% - $grid__gutter);
            display: flex;
            flex-flow: column;

            > a {
                position: relative;

                .content__container {
                    background: linear-gradient(180deg, rgba(0, 0, 0, .1) 0%, rgba(0, 0, 0, .3) 60%, rgba(0, 0, 0, .5) 100%);
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    z-index: 1;
                    display: flex;
                    flex-flow: column;
                    justify-content: flex-end;

                    .--content {
                        background-image: url("data:image/svg+xml,%3Csvg width='500' height='270' viewBox='0 0 500 270' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M500 269.647L454.902 195.052L367.046 47.0084C351.558 21.3634 313.344 0.353134 280.69 0.353131L272.747 0.353131L272.747 2.50457e-05L2.36041e-05 1.20138e-06L0 270L500 269.647Z' fill='url(%23paint0_linear_1_13)'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_1_13' x1='250' y1='270' x2='250' y2='2.18557e-05' gradientUnits='userSpaceOnUse'%3E%3Cstop/%3E%3Cstop offset='0.2' stop-opacity='0.8'/%3E%3Cstop offset='1' stop-opacity='0.6'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");
                        background-size: 100%;
                        padding: .8rem 32% .8rem $grid__padding;

                        h2 {
                            @include title-after();
                            @include text-shadow();
                            color: color(Base, White);
                            font-size: 2.2rem;
                            line-height: 1.4;
                        }

                        p {
                            @include text-shadow();
                            color: color(Base, White);
                            max-height: 0;
                            overflow: hidden;
                            transition: max-height .6s cubic-bezier(0, 1, 0, 1) .1s;
                            font-size: 1.5rem;
                            line-height: 1.4;
                        }
                    }
                }

                picture {
                    height: 30rem;
                    display: flex;
                    overflow: hidden;

                    img {
                        @include transition();
                        object-fit: cover;
                        object-position: center;
                        width: 100%;
                        height: 100%;
                    }
                }

                @include hover() {
                    .content__container {
                        p {
                            max-height: 100rem;
                            transition: max-height 1s ease-in-out .1s;
                        }
                    }

                    picture img {
                        transform: scale(1.1);
                    }
                }
            }

            .--bottom {
                background: color(Base, Black);
                display: flex;
                align-items: center;
                gap: $grid__padding;
                padding: .8rem $grid__padding;
                flex: 1;
                min-height: 5rem;

                h3 {
                    font-size: 1.4rem;
                    line-height: normal;
                    color: color(Base, White);
                }

                .--tags {
                    display: flex;
                    gap: .8rem;
                    flex: 1;
                    overflow: hidden;
                    position: relative;
                    padding-right: $grid__padding;

                    &::after {
                        content: '';
                        display: block;
                        width: $grid__padding;
                        height: 100%;
                        position: absolute;
                        top: 0;
                        right: 0;
                        background: linear-gradient(90deg, rgba(0, 0, 0, 0) 20%, rgba(0, 0, 0, 1) 100%);
                    }

                    a {
                        text-wrap: nowrap;
                        @include breakpoint(small only) {
                            overflow:initial;
                            &:nth-child(n+3){
                                display:none;
                            }
                        }
                    }
                }

                time {
                    font-size: 1.2rem;
                    font-weight: 600;
                    line-height: normal;
                    color: color(Base, White);
                    margin-left: auto;
                }
            }
        }

        .pagination {
            margin: $grid__gutter 0;
        }
    }
}

.side__bar-navigation {
    @include side-bar();
    margin-top: 2.4rem;
}

.page-builder__blog-title {
    .blog-post_header {
        border-bottom: .1rem solid color(Gray, Gray-200);
        padding: 0 0 .8rem;

        img {
            width: 100%;
            object-fit: cover;
            max-height: 63.5vh;
            margin-bottom: 2.4rem;
            @include breakpoint(small only){
                margin-bottom: .8rem;
            }
        }

        time {
            font-size: 1.3rem;
            color: color(Gray, Gray-700);
            margin-right: .2rem;
        }

        span {
            font-size: 1.3rem;
            color: color(Gray, Gray-700);
        }
    }
}

.page-builder__blog-tags {
    .blog-post_tags {
        display: flex;
        gap: 1.2rem;
        flex-wrap: wrap;
        border-top: .1rem solid color(Gray, Gray-200);
        padding: $grid__padding 0 0;

        h3 {
            font-size: 1.6rem;
            color: color(Gray, Gray-900);
        }

        .--tags {
            display: flex;
            gap: .8rem;
            flex-wrap: wrap;
        }
    }
}
