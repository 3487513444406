.quick-buy__container {
    display: flex;
    gap: 2.4rem;

    @include breakpoint(small only) {
        flex-direction: column;
        align-content: space-between;
    }

    .product__gallery {

        @include breakpoint(medium) {
            max-width: 25.2rem;
        }

        .blaze-container {
            @include border-radius();
            border: .1rem solid color(Gray, Gray-150);

            .--image {
                aspect-ratio: 1;
                display: flex;

                img {
                    object-fit: contain;
                    width: 100%;
                    height: 100%;
                }
            }
        }

        .blaze-pagination {
            margin: .8rem 0 0;
        }
    }

    .product__information {
        flex: 1;

        .product__name {
            font-size: 2rem;
            line-height: 2.6rem;
            margin: 0 0 $grid__padding;
        }

        .price__holder {
            @include price-component();
            margin: 0 0 $grid__padding;

            font-size: 2.4rem;
            line-height: 3.7rem;

            .price__old-price {
                font-size: 1.6rem;
                line-height: 2.7rem;

                .--decimal {
                    font-size: 1.2rem;
                    line-height: 2.4rem;
                }
            }

            .price__final-price, .price__special-price {
                font-size: 2.4rem;
                line-height: 3.7rem;

                .--decimal {
                    font-size: 1.6rem;
                    line-height: 3.3rem;
                }
            }
        }

        @include breakpoint(small only) {
            .add-to-cart__holder {
                button {
                    margin: $grid__padding 0 0;
                    width: 100%;
                    text-align: center;
                }
            }
        }
    }
}

.add-to-cart__holder {
    dl.--swatch {

        dt {
            display: flex;
            align-items: center;
            gap: $grid__padding;
            font-size: 1.8rem;
            line-height: 2.8rem;
            font-weight: 700;
        }

        dd {
            margin: 1.2rem 0 0;
            display: flex;
            flex-wrap: wrap;
            gap: .8rem;
            align-items: center;
            position: relative;

            label {
                @include clickable-label-item();
                min-width: 15%;

                > span {
                    white-space: nowrap;
                }
            }
        }
    }

    .swatch__add-to-cart {
        @include border-radius();
        margin: $grid__padding 0 0;
        padding: $grid__padding;
        background-color: color(Gray, Gray-200);

        @include breakpoint(medium) {
            max-width: 80%;
        }

        button {
            font-size: 1.6rem;
            padding: 1.2rem 2.5rem;
            text-align: center;
            width: 100%;
        }
    }
}
