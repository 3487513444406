.page-builder__advice__block {
    ._header {
        @include breakpoint(small only) {
            padding: 0 $grid__padding;
        }

        > a {
            margin-left: auto;
        }
    }

    .blaze-slider {
        @include breakpoint(small only) {
            .blaze-container {
                padding: 0 0 0 $grid__padding;
            }

            + a {
                margin-top: $grid__padding;
                margin-left: $grid__padding;
            }
        }

        .advice__blocks {
            padding-bottom: .1rem;
        }
    }

    .advice__blocks {
        > a, > div {
            @include border-radius();
            border: .1rem solid color(Gray, Gray-300);
            overflow: hidden;
            display: block;
            position: relative;

            picture {
                display: flex;

                img {
                    width: 100%;
                    height: auto;
                }
            }

            .--content {
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                padding: clamp(1.4rem, 0.9rem + 1vw, 2.2rem) clamp(1.6rem, 0.6rem + 2vw, 3.2rem);

                .--title {
                    font-size: 2.4rem;
                    line-height: 3.6rem;
                    font-weight: 700;
                    color: color(Base, White);
                    display: block;
                    margin-bottom: .4rem;
                }

                .--button {
                    width: 100%;
                }

                &.--full-background {
                    &.--black {
                        background-color: color(Base, Black);
                    }

                    &.--red {
                        background-color: color(Base, PrimaryColor);
                    }

                    &.--gold {
                        background-color: color(Base, SecondaryColor);
                    }
                }
            }
        }
    }
}
