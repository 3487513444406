.home__categories {
    background-color: color(Gray, Gray-200);
    padding: $grid__padding;

    ul {
        display: flex;
        flex-direction: column;
        gap: .6rem;

        li {
            a {
                background-color: color(Base, White);
                display: flex;
                align-items: center;
                gap: $grid__padding;
                background-image: url("data:image/svg+xml,%3Csvg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='mask0_471_38812' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='24' height='25'%3E%3Crect y='0.5' width='24' height='24' fill='%23D9D9D9'/%3E%3C/mask%3E%3Cg mask='url(%23mask0_471_38812)'%3E%3Cpath d='M12.9457 12.5L8.3457 7.90002L9.39953 6.84619L15.0534 12.5L9.39953 18.1538L8.3457 17.1L12.9457 12.5Z' fill='black'/%3E%3C/g%3E%3C/svg%3E%0A");
                background-repeat: no-repeat;
                background-position: right $grid__padding center;

                picture {
                    display: flex;
                }
            }
        }
    }
}
