.catalog__media-container {
    .product__media {
        position: relative;

        .add__wishlist {
            @include transition();
            position: absolute;
            top: 0;
            right: 0;
            z-index: 3;
            width: 3.2rem;
            height: 3.2rem;
            background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M22.1402 6.67192C23.5256 6.67192 24.8622 7.15299 25.9029 8.02836C27.6614 9.50483 28.5807 11.4479 28.6366 13.8036C28.6618 14.8983 28.2369 15.8566 27.8643 16.5685C26.9948 18.2349 25.8057 19.5692 24.7579 20.6547C22.8813 22.5997 20.735 24.4281 18.0026 26.4117L18.0001 26.4136C17.8878 26.4981 17.6639 26.6667 17.3336 26.6667C17.1174 26.6667 16.9102 26.5953 16.7168 26.4541C13.6886 24.2531 11.3635 22.2382 9.39926 20.1153C8.3268 18.9565 7.4933 17.8324 6.85087 16.6789C5.64898 14.5228 5.76609 12.2182 7.19698 9.82876C8.21112 8.13634 9.66334 7.1043 11.5105 6.76351C13.7365 6.34992 15.9871 7.28507 17.3341 9.10763C17.5081 8.87047 17.6935 8.65211 17.8912 8.44869C18.9912 7.31881 20.5401 6.67192 22.1402 6.67192ZM23.6072 19.5065C24.5744 18.5049 25.6667 17.282 26.441 15.8002C26.7392 15.2295 27.0412 14.5517 27.0256 13.8407C26.9805 11.9569 26.2779 10.4674 24.8764 9.29081C24.123 8.65741 23.1515 8.3089 22.1402 8.3089C20.9673 8.3089 19.8365 8.77985 19.0371 9.59979C18.6559 9.99216 18.3435 10.453 18.0571 11.0469C17.9239 11.3235 17.6503 11.4995 17.3474 11.5053C17.0582 11.498 16.7652 11.3438 16.6229 11.0724L16.4845 10.8068C15.6685 9.21657 14.2239 8.30071 12.5929 8.30071C12.3298 8.30071 12.0629 8.32481 11.7992 8.37398C10.4234 8.62801 9.33857 9.40408 8.57429 10.6805C7.4383 12.5759 7.33637 14.2254 8.25284 15.8691C8.83174 16.9079 9.5908 17.9284 10.5736 18.9903C12.3943 20.9589 14.5482 22.8384 17.3308 24.8851C19.8877 23.008 21.8719 21.3055 23.6072 19.5065Z' fill='black'/%3E%3C/svg%3E%0A");

            &.--active {
                background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M25.9029 8.02836C24.8622 7.15299 23.5256 6.67192 22.1403 6.67192C20.5401 6.67192 18.9912 7.31881 17.8912 8.44869C17.6935 8.65211 17.5081 8.87047 17.3341 9.10763C15.9871 7.28507 13.7365 6.34992 11.5105 6.76351C9.66334 7.1043 8.21112 8.13634 7.19698 9.82876C5.76609 12.2182 5.64898 14.5228 6.85087 16.6789C7.4933 17.8324 8.3268 18.9565 9.39926 20.1153C11.3635 22.2382 13.6886 24.2531 16.7168 26.4541C16.9102 26.5953 17.1174 26.6667 17.3336 26.6667C17.6665 26.6667 17.8912 26.4955 18.0026 26.4117C20.735 24.4281 22.8813 22.5997 24.7579 20.6547C25.8057 19.5692 26.9948 18.2349 27.8643 16.5685C28.2369 15.8566 28.6618 14.8983 28.6366 13.8036C28.5807 11.4479 27.6614 9.50483 25.9029 8.02836Z' fill='%23FC0F40'/%3E%3C/svg%3E%0A");
            }
        }

        .product__media-gallery {
            @include breakpoint(medium) {
                @include border-radius();
                position: relative;
                border: .1rem solid color(Gray, Gray-150);
            }

            .gallery__image-container {
                @include background-image-logo();
                aspect-ratio: 1;
                background-size: 30%;

                picture {
                    display: flex;
                    justify-content: center;
                    aspect-ratio: 1;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: scale-down;
                    }
                }
            }

            .video-wrapper {
                background-color: color(Base, Black);
                margin: auto;
            }

            .blaze-pagination {
                margin: .8rem 0 0;
            }
        }

        .product__media-thumbnails {
            margin: $grid__padding 0 0;

            @include breakpoint(medium) {
                .blaze-slider {
                    --slides-to-show: 6;
                }
            }

            .blaze-track {
                button {
                    @include transition();
                    @include border-radius(.2rem);
                    @include background-image-logo();
                    padding: .6rem;
                    display: flex;
                    overflow: hidden;
                    border: .1rem solid color(Gray, Gray-150);
                    aspect-ratio: 1;

                    picture {
                        display: flex;

                        img {
                            max-height: 8rem;
                            object-fit: scale-down;
                        }
                    }

                    &.--active {
                        border-color: color(Base, Black);
                    }

                    @include hover() {
                        border-color: color(Base, Black);
                    }
                }
            }

            .video__thumbnail {
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: color(Base, Black);

                > span {
                    display: block;
                    position: absolute;

                    &:first-of-type {
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;
                        background-color: rgba(0, 0, 0, .4);
                        z-index: 1;
                    }

                    &:last-of-type {
                        width: 4.4rem;
                        height: 4.4rem;
                        background-image: url("data:image/svg+xml,%3Csvg width='33' height='30' viewBox='0 0 33 30' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M31.9481 16.4573C32.5954 15.2662 32.5954 13.8284 31.9481 12.6374L26.2164 2.09034C25.5164 0.802289 24.1679 0.000337845 22.7019 0.000308272L10.446 6.1036e-05C8.97998 3.14621e-05 7.63139 0.801991 6.93138 2.0901L1.19969 12.637C0.552416 13.8281 0.552415 15.2659 1.19969 16.4569L6.93143 27.004C7.63142 28.292 8.97992 29.094 10.4459 29.094L22.7018 29.0943C24.1678 29.0943 25.5164 28.2924 26.2164 27.0042L31.9481 16.4573ZM13.095 7.19101C12.4189 6.81962 11.5923 7.30819 11.5923 8.07982V19.5225C11.5923 19.6987 11.6382 19.8719 11.7255 20.025C11.8128 20.1781 11.9385 20.3058 12.0901 20.3955C12.2418 20.4852 12.4143 20.5339 12.5905 20.5366C12.7667 20.5394 12.9406 20.4962 13.095 20.4113L23.4974 14.69C23.6567 14.6025 23.7895 14.4739 23.882 14.3175C23.9745 14.1612 24.0233 13.9828 24.0233 13.8012C24.0233 13.6195 23.9745 13.4412 23.882 13.2848C23.7895 13.1285 23.6567 12.9998 23.4974 12.9124L13.095 7.19101Z' fill='#{color(Base, PrimaryColor)}'/%3E%3C/svg%3E%0A");
                        background-repeat: no-repeat;
                        background-position: center;
                        z-index: 2;
                    }
                }
            }
        }
    }
}
