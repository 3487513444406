.progress-bar {
    margin: 2.4rem 0;

    @include breakpoint(small only) {
        margin: 20px 0 0;
    }
    position: relative;
    display: flex;

    &:before {
        content: '';
        position: absolute;
        height: 2px;
        background: color(Gray, Gray-300);
        bottom: 9px;
        left: 25px;
        right: 25px;

        @include breakpoint(small only) {
            left: 5px;
            right: 5px;
        }
    }

    li {
        position: relative;
        flex: 1 13%;
        z-index: 1;
        text-align: center;

        > span {
            position: relative;
            z-index: 3;
            font-size: 15px;
            line-height: 23px;
            color: color(Gray, Gray-800);
            display: inline-block;

            &::after {
                content: '';
                margin: 8px auto 0;
                display: block;
                width: 2rem;
                height: 1.7rem;
                background-image: url("data:image/svg+xml,%3Csvg width='20' height='17' viewBox='0 0 20 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.9832 0H6.01685C5.29171 0 4.61764 0.369248 4.25508 0.966848L0.271926 7.53072C-0.0906422 8.12832 -0.0906422 8.86682 0.271926 9.46928L4.25508 16.0332C4.61764 16.6308 5.29171 17 6.01685 17H13.9832C14.7083 17 15.3824 16.6308 15.7449 16.0332L19.7281 9.46928C20.0906 8.87168 20.0906 8.13318 19.7281 7.53072L15.7449 0.966848C15.3824 0.369248 14.7083 0 13.9832 0Z' fill='#{color(Gray, Gray-500)}'/%3E%3C/svg%3E%0A");
            }
        }

        &._active {
            &::before {
                content: '';
                position: absolute;
                height: 2px;
                background: color(Base, Black);
                bottom: 9px;
                left: 0;
                right: 50%;
                z-index: 1;
            }

            span {
                color: color(Base, Black);
                font-weight: 700;

                &::after {
                    background-image: url("data:image/svg+xml,%3Csvg width='20' height='17' viewBox='0 0 20 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.9832 0H6.01685C5.29171 0 4.61764 0.369248 4.25508 0.966848L0.271926 7.53072C-0.0906422 8.12832 -0.0906422 8.86682 0.271926 9.46928L4.25508 16.0332C4.61764 16.6308 5.29171 17 6.01685 17H13.9832C14.7083 17 15.3824 16.6308 15.7449 16.0332L19.7281 9.46928C20.0906 8.87168 20.0906 8.13318 19.7281 7.53072L15.7449 0.966848C15.3824 0.369248 14.7083 0 13.9832 0Z' fill='#{color(Base, Black)}'/%3E%3C/svg%3E%0A");
                }
            }
        }

        &._complete {
            &::before {
                content: '';
                position: absolute;
                height: 2px;
                background: color(Base, Black);
                bottom: 9px;
                right: 0;
                left: 0;
                z-index: 1;
            }

            span {
                color: color(Base, Black);
                font-weight: 700;

                &::after {
                    background-image: url("data:image/svg+xml,%3Csvg width='20' height='17' viewBox='0 0 20 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.9832 0H6.01685C5.29171 0 4.61764 0.369248 4.25508 0.966848L0.271926 7.53072C-0.0906422 8.12832 -0.0906422 8.86682 0.271926 9.46928L4.25508 16.0332C4.61764 16.6308 5.29171 17 6.01685 17H13.9832C14.7083 17 15.3824 16.6308 15.7449 16.0332L19.7281 9.46928C20.0906 8.87168 20.0906 8.13318 19.7281 7.53072L15.7449 0.966848C15.3824 0.369248 14.7083 0 13.9832 0Z' fill='#{color(Base, Black)}'/%3E%3C/svg%3E%0A");
                }
            }
        }

        &:first-of-type {
            text-align: left;
            flex: 1;

            &._active {
                &::before {
                    display: none;
                }

                span::after {
                    background-image: url("data:image/svg+xml,%3Csvg width='20' height='17' viewBox='0 0 20 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.9832 0H6.01685C5.29171 0 4.61764 0.369248 4.25508 0.966848L0.271926 7.53072C-0.0906422 8.12832 -0.0906422 8.86682 0.271926 9.46928L4.25508 16.0332C4.61764 16.6308 5.29171 17 6.01685 17H13.9832C14.7083 17 15.3824 16.6308 15.7449 16.0332L19.7281 9.46928C20.0906 8.87168 20.0906 8.13318 19.7281 7.53072L15.7449 0.966848C15.3824 0.369248 14.7083 0 13.9832 0Z' fill='#{color(Base, Black)}'/%3E%3C/svg%3E%0A");
                }
            }

            &._complete::before {
                left: 25px;

                @include breakpoint(small only) {
                    left: 5px;
                }
            }
        }

        &:last-of-type {
            text-align: right;
            flex: 1;

            &._complete:before {
                right: 25px;

                @include breakpoint(small only) {
                    right: 5px;
                }
            }
        }
    }
}
