.home__slider-container {
    position: relative;

    picture {
        display: flex;

        img {
            width: 100%;
            height: auto;
        }
    }

    .slider__bullets {
        position: absolute;
        bottom: $grid__padding;
        z-index: 1;
    }
}
