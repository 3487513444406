.breadcrumbs {
    margin: .9rem 0;

    @include breakpoint(medium) {
        margin: 2.4rem 0;
    }

    ol {
        display: flex;
        align-items: center;
        gap: .3rem;

        li {
            display: flex;
            align-items: center;
            gap: .3rem;

            &:first-child {
                a {
                    width: 1.8rem;
                    height: 1.8rem;
                    background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='mask0_471_40505' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='18' height='18'%3E%3Crect width='18' height='18' fill='%23D9D9D9'/%3E%3C/mask%3E%3Cg mask='url(%23mask0_471_40505)'%3E%3Cpath d='M3 15.75V6.75L9 2.25L15 6.75V15.75H10.5V10.5H7.5V15.75H3Z' fill='%23D4AF37'/%3E%3C/g%3E%3C/svg%3E%0A");

                    span {
                        display: none;
                    }
                }
            }

            a, span {
                font-size: 1.2rem;

                &._sep {
                    background-repeat: no-repeat;
                    background-position: center;
                    display: block;
                    width: 1.8rem;
                    height: 1.8rem;
                    background-image: url("data:image/svg+xml,%3Csvg width='6' height='10' viewBox='0 0 6 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.70963 4.99989L0.259628 1.54989L1.05 0.759521L5.29037 4.99989L1.05 9.24026L0.259628 8.44989L3.70963 4.99989Z' fill='black'/%3E%3C/svg%3E%0A");
                }

                &._current span {
                    font-weight: 700;
                }
            }
        }
    }
}
