#offCanvas-addToCart.off-canvas {
    height: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;

    @include breakpoint(medium up) {
        transform: translateX(-32rem);
    }

    @include breakpoint(large up) {
        transform: translateX(-42rem);
    }

    &.is-open {
        transform: translateX(0);

        @include breakpoint(medium up) {
            width: 32rem;
        }

        @include breakpoint(large up) {
            width: 42rem;
        }
    }

    .add-to-cart__popup-product {
        display: flex;
        border-bottom: .1rem solid color(Gray, Gray-300);
        border-top: .1rem solid color(Gray, Gray-300);

        .price__holder{
            padding-top:0;
        }
        .product__image {
            display: block;
            padding: .8rem 0;

            img {
                width: 10rem;
                height: 10rem;
                margin: 0 .8rem;

                @include breakpoint(small only) {
                    width: 7rem;
                    height: 7rem;
                }
            }
        }

        .product__information {
            padding: .8rem;
            flex:1;
            > span {
                font-size: 1.5rem;
                font-weight: 600;
            }

            > ul {
                font-size: 1.4rem;
                line-height: 1.8rem;
                margin: .8rem 0 0;
            }
            .price__holder{
                padding-top: 0;
            }
        }

        .price__holder {
            @include price-component();
            flex-flow: column;
            align-items: flex-end;
            padding: .8rem;
            gap: 0;

            @include breakpoint(medium) {
                font-size: 1.6rem;
                line-height: normal;
            }
            .price__old-wrapper{
                width:11.5rem;
                display: flex;
                gap: 0;
                align-items: end;
                justify-content: end;
                flex-direction: column;
            }
            .price__old-label{
                font-weight: 500;
                font-size:1.1rem;
            }
            .price__old-price {
                font-size: 1.6rem;
                line-height: normal;
                margin-bottom: .5rem;
            }
            .price__normal{
                display:flex;
                gap:.2rem;
                font-size:2.4rem;
                line-height: 2rem;
                .--decimal{
                    font-size:1.4rem;
                    line-height: 2rem;
                }
            }

        }
    }

    .modal__content {
        flex: 1;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;

        .--player {
            border-bottom: .1rem solid color(Gray, Gray-300);

            > span {
                display: flex;
                background-color: color(Gray, Gray-200);
                padding: 1rem;
                font-weight: 700;
                font-size: 1.4rem;
            }

            .--product {
                display: flex;
                gap: .8rem;
                padding: $grid__padding;
                border-top: .1rem solid color(Gray, Gray-300);

                .--info {
                    flex: 1;

                    > span {
                        font-size: 1.4rem;
                    }

                    .--options {
                        margin: .4rem 0;
                        font-size: 1.2rem;

                        span {
                            display: block;
                        }
                    }
                }
            }
        }

        .add-to-cart__popup-products {
            margin: .8rem 0 0;


            h3 {
                @include title-after();
                font-size: 2rem;
                line-height: 2.4rem;
                margin: 0 0 .8rem;
                padding: 0 .8rem;
            }

            ul {
                li > a, li > div {
                    display: flex;
                    border-bottom: .1rem solid color(Gray, Gray-300);
                    padding: $grid__padding .8rem;
                    gap: .8rem;

                    .product__image {
                        display: block;
                        padding: .8rem 0;
                        flex: 0 0 7rem;

                        img {
                            width: 6rem;
                            height: 6rem;
                            margin: 0 .8rem;
                        }
                    }

                    .--right {
                        flex: 1;

                        .product__information {
                            justify-content: space-between;
                            gap: $grid__padding;
                        }

                        .add-to-cart__holder {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            gap: .8rem;
                            margin-top: $grid__padding;
                            flex-wrap: wrap;

                            .--option select {
                                font-size: 1.2rem;
                                height: 3.8rem;
                                line-height: 3.8rem;
                                padding: 0 3rem 0 1rem;
                                background-size: 1.2rem;
                                background-position: right 1rem center;
                            }

                            button.--loading {
                                padding: 0;
                            }
                        }

                        .--actions {
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;
                            align-items: flex-end;
                            gap: .4rem;
                            flex-grow:1;

                            .price__holder {
                                @include price-component();
                                flex-flow: column;
                                align-items: flex-end;
                                gap: 0;
                                font-size: 2.4rem;
                                line-height: 2.4rem;
                                width: 100%;
                                .--decimal {
                                    line-height: 2rem;
                                    font-size: 1.4rem;
                                }

                                .price__final-price, .price__special-price {
                                    font-size: 2.4rem;
                                    line-height: 2.4rem;

                                    .--decimal {
                                        line-height: 2rem;
                                        font-size: 1.4rem;
                                    }
                                }
                                .price__old-wrapper{
                                    width: 100%;
                                    display: flex;
                                    gap: 0;
                                    justify-content: end;
                                    flex-direction: column;
                                    align-items:end;
                                    margin-bottom: .5rem;
                                    .price__old-label{
                                        font-weight: 500;
                                    }
                                }
                                .price__old-price {
                                    font-size: 1.6rem;
                                    line-height: 1.7rem;

                                    .--decimal {
                                        line-height: 1.5rem;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .modal__footer {
        background-color: color(Gray, Gray-200);
        padding: $grid__padding;

        ._actions {
            display: flex;
            flex-direction: column;
            gap: .8rem;
            align-items: center;
        }

        a {
            display: block;
            text-align: center;
            margin: 0 auto .8rem;
        }

        button {
            position: relative;
            cursor: pointer;
            font-size: 1.4rem;
            display: block;
            text-align: center;
            width: 100%;

            &:hover {
                text-decoration: underline;
            }
        }
    }
}
