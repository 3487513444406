.category__product-types {
    ._header {
        display: flex;
        align-items: center;
        gap: $grid__padding;
        margin: $grid__padding;

        @include breakpoint(medium) {
            margin: $grid__padding 0;
        }

        h3 {
            @include title-after();
        }
    }

    .--block {
        @include border-radius();
        border: .1rem solid color(Gray, Gray-300);
        overflow: hidden;
        display: flex;
        flex-direction: column;
        margin: 0 0 $grid__padding 0;

        @include breakpoint(medium) {
            height: 100%;
            margin: 0;
        }

        picture {
            display: flex;
        }

        ._content {
            position: relative;
            padding: $grid__padding;

            .preview__container {
                position: absolute;
                right: 3.25rem;
                top: -3.25rem;
                filter: drop-shadow(1rem -.6rem 0 color(Gray, Gray-200));

                @include breakpoint(medium) {
                    right: 2rem;
                    top: -4.5rem;
                }

                ._preview {
                    mask-image: url("data:image/svg+xml,%3Csvg width='74' height='65' viewBox='0 0 74 65' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M51.7377 0H22.2623C19.5793 0 17.0853 1.41183 15.7438 3.69677L1.00613 28.7939C-0.335376 31.0789 -0.335376 33.9025 1.00613 36.2061L15.7438 61.3032C17.0853 63.5882 19.5793 65 22.2623 65H51.7377C54.4207 65 56.9147 63.5882 58.2562 61.3032L72.9939 36.2061C74.3354 33.9211 74.3354 31.0975 72.9939 28.7939L58.2562 3.69677C56.9147 1.41183 54.4207 0 51.7377 0Z' fill='%23D4AF37'/%3E%3C/svg%3E%0A");
                    mask-size: 100%;
                    width: 7.4rem;
                    height: 6.5rem;
                }
            }

            > span {
                display: block;
                font-size: 2rem;
                font-weight: 700;
                line-height: 3rem;

                @include breakpoint(medium) {
                    margin-bottom: .8rem;
                }
            }
        }
    }
}
