.inline__filter-container {
    @include breakpoint(small only) {
        background-color: color(Gray, Gray-200);
        padding: 1.2rem 0 .8rem $grid__padding;
    }

    @include breakpoint(medium) {
        margin: $grid__padding 0;
    }

    ._title {
        font-weight: 700;
        font-size: 1.6rem;
        line-height: 2.4rem;
    }

    ._options {
        margin: 1.2rem 0 0;
        display: flex;
        white-space: nowrap;
        overflow: auto;
        gap: .4rem;
        touch-action: pan-x;
        scroll-behavior: smooth;
        padding: 0 0 .8rem;

        @include breakpoint(small only) {
            scroll-snap-type: x proximity;
        }

        label {
            @include clickable-label-item();
            padding: .4rem $grid__padding;
            background-color: color(Base, White);
        }
    }
}
