.faq__block {
    margin: 2rem 0;

    @include breakpoint(small only) {
        ._header {
            padding: 0 $grid__padding;
        }
    }

    .faq__list {
        background: color(Base, White);

        details {
            summary {
                border-bottom: .1rem solid color(Gray, Gray-300);
                cursor: pointer;
                font-size: 1.6rem;
                line-height: 2.4rem;
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: .8rem;
                padding: $grid__padding 5.5rem $grid__padding $grid__padding;
                font-weight: 300;
                background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='mask0_471_40655' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='24' height='24'%3E%3Crect width='24' height='24' fill='%23D9D9D9'/%3E%3C/mask%3E%3Cg mask='url(%23mask0_471_40655)'%3E%3Cpath d='M12 15.375L6 9.37498L7.4 7.97498L12 12.575L16.6 7.97498L18 9.37498L12 15.375Z' fill='black'/%3E%3C/g%3E%3C/svg%3E%0A");
                background-repeat: no-repeat;
                background-position: right $grid__padding top $grid__padding;

                @include breakpoint(medium) {
                    padding: $grid__padding 2.4rem $grid__padding 0;
                    background-position: right top $grid__padding;
                }

                @include hover() {
                    &::after {
                        background-image: url("data:image/svg+xml,%3Csvg width='23' height='20' viewBox='0 0 23 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16.0806 0H6.91938C6.08547 0 5.31029 0.43441 4.89334 1.13747L0.312715 8.85967C-0.104238 9.56273 -0.104238 10.4316 0.312715 11.1403L4.89334 18.8625C5.31029 19.5656 6.08547 20 6.91938 20H16.0806C16.9145 20 17.6897 19.5656 18.1067 18.8625L22.6873 11.1403C23.1042 10.4373 23.1042 9.56845 22.6873 8.85967L18.1067 1.13747C17.6897 0.43441 16.9145 0 16.0806 0Z' fill='#{color(Base, Black)}'/%3E%3C/svg%3E%0A");
                        color: color(Base, SecondaryColor);
                    }
                }
            }

            .--content {
                padding: 0 $grid__padding $grid__padding;
                font-size: 1.6rem;
                line-height: 2.4rem;
                border-bottom: .1rem solid color(Gray, Gray-300);
            }

            &[open] summary {
                border: none;
                background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='mask0_2_8' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='24' height='24'%3E%3Crect x='24' y='24' width='24' height='24' transform='rotate(-180 24 24)' fill='%23D9D9D9'/%3E%3C/mask%3E%3Cg mask='url(%23mask0_2_8)'%3E%3Cpath d='M12 8.625L18 14.625L16.6 16.025L12 11.425L7.4 16.025L6 14.625L12 8.625Z' fill='black'/%3E%3C/g%3E%3C/svg%3E%0A");
            }

            &[open] summary ~ * {
                animation: open .5s ease-in-out;
            }

            @keyframes open {
                0% {
                    opacity: 0;
                }
                100% {
                    opacity: 1;
                }
            }
        }

        + .--transparent-button-with-arrow {
            margin: $grid__padding 0 0;

            @include breakpoint(small only) {
                margin-right: $grid__padding;
            }
        }
    }

    .--hexagon {
        margin: $grid__padding 0 0;
    }

    .right__content {
        @include border-radius();
        display: flex;
        overflow: hidden;
        position: relative;

        @include breakpoint(small only) {
            margin: $grid__padding;
        }

        picture {
            display: flex;
        }

        .--background {
            position: absolute;
            left: 0;
            bottom: 0;
            top: 0;
            right: 0;
            background-image: url("data:image/svg+xml,%3Csvg width='323' height='136' viewBox='0 0 323 136' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath opacity='0.9' fill-rule='evenodd' clip-rule='evenodd' d='M322.263 135.989H0V0L233.935 1.52588e-05C240.009 1.52588e-05 245.621 3.24019 248.657 8.50002L322.263 135.989Z' fill='black'/%3E%3C/svg%3E%0A");
            background-repeat: no-repeat;
            background-position: left bottom -32%;
            display: flex;
            align-items: flex-end;
            background-size: 70%;
            padding: $grid__padding;

            @include breakpoint(medium) {
                background-position: left bottom;
                background-size: auto;
            }

            span {
                @include transition();
                @include text-shadow();
                display: flex;
                align-items: center;
                color: color(Base, White);
                font-weight: 700;
                background-image: url("data:image/svg+xml,%3Csvg width='48' height='48' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M46.701 21.75C47.5048 23.1423 47.5048 24.8577 46.701 26.25L37.299 42.5346C36.4952 43.9269 35.0096 44.7846 33.4019 44.7846L14.5981 44.7846C12.9904 44.7846 11.5048 43.9269 10.701 42.5346L1.29904 26.25C0.495193 24.8577 0.495193 23.1423 1.29904 21.75L10.701 5.46539C11.5048 4.07308 12.9904 3.21539 14.5981 3.21539L33.4019 3.21539C35.0096 3.21539 36.4952 4.07309 37.299 5.46539L46.701 21.75Z' fill='white'/%3E%3Cmask id='mask0_3_14' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='12' y='12' width='24' height='24'%3E%3Crect x='12' y='12' width='24' height='24' fill='%23D9D9D9'/%3E%3C/mask%3E%3Cg mask='url(%23mask0_3_14)'%3E%3Cpath d='M26 30L24.6 28.55L28.15 25H16V23H28.15L24.6 19.45L26 18L32 24L26 30Z' fill='%23FC0F40'/%3E%3C/g%3E%3C/svg%3E%0A");
                background-repeat: no-repeat;
                background-position: right center;
                padding-right: 6.2rem;
                max-width: 50%;
                font-size: 1.4rem;
                line-height: 1.8rem;
                min-height: 4.8rem;

                @include breakpoint(medium) {
                    max-width: 40%;
                    font-size: 2.4rem;
                    line-height: 3.2rem;
                    display: block;

                    @include tablet-only() {
                        font-size: 1.6rem;
                        line-height: 2.4rem;
                    }
                }
            }
        }

        &:is(a) {
            @include hover() {
                .--background span {
                    background-image: url("data:image/svg+xml,%3Csvg width='48' height='48' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M46.701 21.75C47.5048 23.1423 47.5048 24.8577 46.701 26.25L37.299 42.5346C36.4952 43.9269 35.0096 44.7846 33.4019 44.7846L14.5981 44.7846C12.9904 44.7846 11.5048 43.9269 10.701 42.5346L1.29904 26.25C0.495193 24.8577 0.495193 23.1423 1.29904 21.75L10.701 5.46539C11.5048 4.07308 12.9904 3.21539 14.5981 3.21539L33.4019 3.21539C35.0096 3.21539 36.4952 4.07309 37.299 5.46539L46.701 21.75Z' fill='%23FC0F40'/%3E%3Cmask id='mask0_3_14' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='12' y='12' width='24' height='24'%3E%3Crect x='12' y='12' width='24' height='24' fill='%23D9D9D9'/%3E%3C/mask%3E%3Cg mask='url(%23mask0_3_14)'%3E%3Cpath d='M26 30L24.6 28.55L28.15 25H16V23H28.15L24.6 19.45L26 18L32 24L26 30Z' fill='white'/%3E%3C/g%3E%3C/svg%3E%0A");
                }
            }
        }
    }
}

.catalog-category-page {
    .faq__block {
        ._header {
            h3 {
                font-size: 2.4rem;
                line-height: 3rem;
            }
        }

        .faq__list {
            margin-top: 2.4rem;
        }

        .--transparent-button-with-arrow {
            margin-top: 2.4rem;

            @include breakpoint(small only) {
                margin-left: $grid__padding;
            }
        }
    }
}
