.home__slider-container {
    .slider__slide {
        position: relative;

        .slider__slide_content__container {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 3rem;
            cursor: pointer;

            @include breakpoint(medium) {
                bottom: 5rem;
            }

            .slider__slide_content {
                display: flex;
                justify-content: space-between;
                flex-direction: column;
                align-items: baseline;
                gap: $grid__padding;

                @include breakpoint(medium) {
                    flex-direction: row;
                    gap: $grid__padding;
                    align-items: flex-end;
                }

                > div {
                    @include breakpoint(medium) {
                        max-width: 80%;
                    }

                    p {
                        @include text-shadow();
                        color: color(Base, White);
                        font-size: 1.8rem;
                        line-height: 2.4rem;
                    }

                    h3 {
                        @include text-shadow();
                        @include title-after();
                        font-size: clamp(2.6rem, 0.4rem + 5.2vw, 5.6rem);
                        line-height: clamp(2.8rem, 0.2rem + 6vw, 6.2rem);
                        color: color(Base, White);
                    }

                    &.button__holder {
                        display: flex;
                        gap: $grid__padding;
                        justify-content: center;
                        align-items: center;

                        @include breakpoint(small only) {
                            flex-wrap: wrap;
                            justify-content: flex-start;
                        }
                    }
                }
            }
        }
    }

    .blaze-pagination {
        position: absolute;
        bottom: .8rem;
        z-index: 1;
        left: 0;
        right: 0;

        @include breakpoint(medium) {
            bottom: 2rem;
        }
    }
}
