.returns__container {
    .returns__search {
        @include border-radius();
        background-color: color(Gray, Gray-200);
        padding: $grid__padding;
        margin: $grid__padding 0 0;

        @include breakpoint(medium) {
            max-width: 70%;
        }

        h3 {
            margin: 0 0 .8rem;
        }

        form {
            display: flex;
            align-items: flex-start;
            gap: 1rem;

            @include breakpoint(small only) {
                flex-direction: column;
                align-items: stretch;

                button {
                    text-align: center;
                }
            }

            .field__container {
                margin: 0;
                padding: .1rem 0;
            }
        }
    }

    .active__order {
        margin: 2.4rem 0 0;

        > .--loading {
            margin-bottom: 2.4rem;
        }

        > section {
            margin-bottom: 2.4rem;

            .--container {
                @include border-radius();
                border: .1rem solid color(Gray, Gray-300);
                overflow: hidden;

                .--header {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    background-color: color(Gray, Gray-200);
                    font-size: 1.6rem;
                    padding: .8rem $grid__padding;

                    @include breakpoint(small only) {
                        flex-direction: column;
                        align-items: stretch;
                        gap: .4rem;
                    }

                    span:last-child {
                        font-size: 1.4rem;
                        color: color(Gray, Gray-800);
                    }
                }

                .--items {
                    font-size: 1.6rem;

                    .--item {
                        padding: .8rem $grid__padding;
                        display: flex;
                        align-items: center;
                        gap: $grid__padding;
                        border-top: .1rem solid color(Gray, Gray-300);
                        position: relative;

                        @include breakpoint(small only) {
                            flex-wrap: wrap;
                        }

                        .--overlay {
                            position: absolute;
                            top: 0;
                            left: 0;
                            right: 0;
                            bottom: 0;
                            background-color: rgba(224, 224, 224, .2);
                        }

                        .--image {
                            display: flex;
                            width: 6rem;
                        }

                        .--information {
                            flex: 1;
                            display: flex;
                            gap: .4rem;
                            flex-direction: column;

                            > span:first-child {
                                font-size: 1.7rem;
                            }

                            .--options {
                                font-size: 1.3rem;

                                > span {
                                    display: flex;
                                    gap: .4rem;
                                    align-items: center;
                                }

                                .--custom {
                                    display: block;
                                    margin-top: .4rem;
                                    color: color(Gray, Gray-700);
                                }
                            }
                        }

                        .--actions {
                            margin-left: auto;
                            display: flex;
                            gap: .8rem;
                            align-items: center;
                            flex: 0 0 35%;

                            @include breakpoint(small only) {
                                flex: 0 0 100%;
                            }

                            span:first-child {
                                flex: 1;
                                display: flex;
                                flex-direction: column;
                                gap: .8rem;
                            }

                            span:last-child {
                                flex: 0 0 25%;
                                text-align: right;
                            }
                        }
                    }
                }
            }

            > .--actions {
                padding: $grid__padding 0;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                border-bottom: .1rem solid color(Gray, Gray-300);

                button, > div {
                    margin-left: auto;

                    @include breakpoint(small only) {
                        width: 100%;
                        text-align: center;

                        label {
                            font-size: 1.2rem;
                        }
                    }
                }
            }
        }
    }

    .returns__complete {
        margin: 2.4rem 0 0;

        .global__content {
            margin: 0 0 2.4rem;
        }

        .--actions {
            display: flex;
            flex-direction: column;
            gap: .8rem;
            justify-content: flex-start;

            > * {
                margin-right: auto;
            }
        }
    }
}

.return__widget {
    margin: 4.8rem 0 0;
}
