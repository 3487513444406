.global__content, .cms-page:not(.error-page) {
    font-size: 1.6rem;
    line-height: 2.4rem;

    h1 {
        font-size: 2.6rem;
        font-weight: 700;
        line-height: normal;
    }

    h2 {
        font-size: 2.4rem;
        font-weight: 700;
        line-height: normal;
    }

    h3 {
        font-size: 2rem;
        font-weight: 600;
        line-height: normal;
    }

    h4 {
        font-size: 1.8rem;
        font-weight: 600;
        line-height: normal;
    }

    p, ul li, ol li, strong {
        a {
            text-decoration: underline;
        }
    }

    > a {
        text-decoration: underline;
    }

    ul {
        margin: 0 0 2rem 2rem;
        list-style: disc outside;
        font-size: 1.6rem;
        line-height: 2.4rem;
    }

    ol {
        margin: 0 0 2rem 2rem;
        list-style: disc outside;
        font-size: 1.6rem;
        line-height: 2.4rem;
    }

    > * {
        margin: 0 0 $grid__padding;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .video-container {
        padding-top: 2.4rem;
    }

    .bookerz__iframe {
        width: 60%;

        @include breakpoint(small only) {
            width: 100%;
        }
    }

    table {
        width: 100%;
        margin: 0 0 $grid__padding;

        tr {
            &:nth-child(even) {
                background: color(Gray, Gray-200);
            }

            th, td {
                text-align: left;
                border: .1rem solid color(Gray, Gray-300);
                border-collapse: collapse;
                padding: .4rem .8rem;

                &:is(th) {
                    font-weight: 600;
                }
            }
        }
    }
}

.error-page {
    font-size: 1.6rem;
    line-height: 2.4rem;
}
