.content__blocks {
    .home__category-list {
        @include breakpoint(small only) {
            .catalog__category-list {
                padding-left: $grid__padding;
            }
        }

        .home__category-list__header {
            display: flex;
            align-items: center;
            gap: $grid__padding;
            margin: $grid__padding 0;

            h2 {
                @include title-after();
            }

            .blaze-controls {
                margin-left: auto;
            }
        }
    }

    .--transparent-button-with-arrow {
        margin: $grid__padding 0;
    }
}
