.language-popup-border {
    z-index: 100000;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, .6);

    @media print {
        display: none;
    }

    .language-popup {
        @include border-radius();
        background: #181818;
        padding: 20px;
        text-align: center;

        @include breakpoint(small only) {
            max-width: 320px;
        }

        p {
            color: color(Base, White);
            font-size: 30px;
            font-weight: 600;

            @include breakpoint(small only) {
                font-size: 20px;
                line-height: 20px;
            }
        }

        ._dropdown {
            color: color(Base, White);
            margin: 30px 0;

            ul li {
                a {
                    display: flex;
                    margin: 10px 0;
                    color: color(Base, White);

                    @include breakpoint(small only) {
                        margin: 20px 0 0;
                    }

                    &:hover span {
                        text-decoration: underline;
                    }

                    span {
                        display: block;

                        img {
                            margin-right: 10px;
                        }

                        &:last-child {
                            flex: 1;
                            text-align: left;
                            color: color(Base, White);

                            @include breakpoint(small only) {
                                font-size: 15px;
                            }
                        }
                    }
                }
            }
        }
    }
}
