.page-builder__image-hover {
    .banner__row {
        display: grid;
        gap: $grid__padding;
        grid-template-columns: repeat(2, 1fr);

        @include breakpoint(medium) {
            grid-template-columns: repeat(4, 1fr);
        }

        > a, > div {
            @include border-radius();
            display: block;
            overflow: hidden;
            position: relative;

            > span:not(.--button) {
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background-color: rgba(0, 0, 0, .2);
                opacity: 0;
                z-index: 1;
                transition: all .5s ease-out .1s;
            }

            picture {
                display: flex;

                img {
                    transition: all .5s ease-out .1s;
                    width: 100%;
                    object-fit: cover;
                }
            }

            .--button {
                position: absolute;
                bottom: $grid__padding;
                left: 1.1rem;
                right: 1.1rem;
                z-index: 3;

                @include breakpoint(medium) {
                    left: 3.6rem;
                    right: 3.6rem;
                    bottom: 2.6rem;

                    @media screen and (max-width: 1230px) {
                        left: 1.1rem;
                        right: 1.1rem;
                    }
                }
            }

            @include hover() {
                > span {
                    opacity: 1;
                }

                img {
                    transform: scale(1.2);
                }
            }
        }
    }
}
