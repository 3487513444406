.catalog__recent-products {
    .--header {
        display: flex;
        align-items: center;
        gap: $grid__padding;
        margin: $grid__padding 0;

        h2 {
            @include title-after();
        }

        .blaze-controls {
            margin-left: auto;
        }
    }

    @include breakpoint(small only) {
        .catalog__category-list {
            padding-left: $grid__padding;
        }
    }
}
