input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type='number'] {
    -moz-appearance: textfield;
}

label {
    display: block;
}

input[type='text'], input[type='search'], input[type='tel'], input[type='email'], input[type='number'], input[type='password'], input[type='date'] {
    @include transition();
    @include border-radius();
    background-color: color(Base, White);
    border: .1rem solid color(Gray, Gray-150);
    height: 4.4rem;
    line-height: 4.4rem;
    font-size: 1.4rem;
    padding: 0 1.5rem;
    width: 100%;
    background-position: right -1.5rem center;

    &.passed {
        background-repeat: no-repeat;
        background-position: right .8rem center;
        background-size: 1.8rem;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='#{color(Alert, Success)}' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='m4.5 12.75 6 6 9-13.5' /%3E%3C/svg%3E%0A");
    }

    &:disabled {
        cursor: not-allowed;
        background-image: none;
        background-color: color(Gray, Gray-300);
    }

    &.--small {
        font-size: 1.2rem;
        height: 2.8rem;
        line-height: 2.8rem;
    }
}

textarea {
    @include border-radius();
    background: color(Base, White);
    border: .1rem solid color(Gray, Gray-150);
    font-size: 1.4rem;
    padding: 1.5rem;
    width: 100%;

    &:disabled {
        cursor: not-allowed;
        background-color: color(Gray, Gray-300);
    }
}

select {
    @include border-radius();
    background-color: color(Base, White);
    border: .1rem solid color(Gray, Gray-150);
    height: 4.4rem;
    line-height: 4.4rem;
    font-size: 1.4rem;
    padding: 0 4.5rem 0 1.5rem;
    width: 100%;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml,%3Csvg width='15' height='8' viewBox='0 0 15 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.792893 0.292893C1.18342 -0.0976311 1.81658 -0.0976311 2.20711 0.292893L7.5 5.58579L12.7929 0.292893C13.1834 -0.0976311 13.8166 -0.0976311 14.2071 0.292893C14.5976 0.683417 14.5976 1.31658 14.2071 1.70711L8.20711 7.70711C7.81658 8.09763 7.18342 8.09763 6.79289 7.70711L0.792893 1.70711C0.402369 1.31658 0.402369 0.683417 0.792893 0.292893Z' fill='black'/%3E%3C/svg%3E%0A");
    background-position: right 1.5rem center;

    &.--small {
        height: 3.5rem;
        line-height: 3.3rem;
        background-size: 1.2rem;
        padding: 0 3rem 0 1rem;
        background-position: right 1rem center;
    }

    &:disabled {
        cursor: not-allowed;
        background-color: color(Gray, Gray-300);
    }
}

.field__container, .checkout__field {
    margin: 0 0 $grid__padding;
    flex: 1;

    &.email {
        margin: 0;
    }

    label, span.label {
        display: flex;
        align-items: center;
        gap: .4rem;
        font-weight: 600;
        font-size: 1.6rem;
        line-height: 2.4rem;
        cursor: pointer;
        margin-bottom: .4rem;

        &:is(span) {
            cursor: default;
        }

        &._margin-right {
            margin-right: $grid__padding;
        }

        em {
            color: color(Base, PrimaryColor);
            font-style: normal;
            margin-left: .5rem;
        }
    }

    input[type='checkbox'], input[type='radio'] {
        @include radio-checkbox();
    }

    > p {
        font-size: 1.4rem;
        line-height: 2rem;
        margin: .4rem 0 0;
        color: color(Gray, Gray-800);
    }
}

.group__container {
    display: flex;
    flex-wrap: wrap;
    column-gap: $grid__padding;

    @include breakpoint(medium) {
        &:not(.--multiple) {
            input, select {
                max-width: 75%;
            }
        }
    }

    .input__checkbox {
        margin: 0 0 $grid__padding;

        > p {
            display: flex;
            align-items: center;
            gap: .4rem;
            font-weight: 600;
            font-size: 1.6rem;
            line-height: 2.4rem;
            cursor: pointer;
            margin-bottom: .8rem;
        }

        > div {
            display: flex;
            align-items: center;
            gap: .4rem;

            input[type='checkbox'], input[type='radio'] {
                @include radio-checkbox();
            }

            label {
                display: flex;
                align-items: center;
                gap: .4rem;
                font-weight: 600;
                font-size: 1.6rem;
                line-height: 2.4rem;
                cursor: pointer;
            }
        }
    }
}

.hexagon__input {
    @include hexagon-input();

    &--gray {
        @include hexagon-input('gray');
    }
}

.field-error {
    color: color(Alert, Error);
    font-size: 1.2rem;
    line-height: 1.8rem;
    margin-top: .5rem;
    display: block;
}

.field__group {
    display: flex;
    flex-wrap: wrap;
    column-gap: $grid__padding;

    &.email {
        margin: 0 0 $grid__padding;
    }

    &.--no-margin {
        margin: 0;
    }

    .rating__group-container {
        display: flex;

        label:first-child {
            line-height: 2rem;
            margin-right: .8rem;
            font-size: 1.4rem;
            color: color(Gray, Gray-800);
        }

        .rating__group {
            display: block;
            float: left;
            margin: 0 0 .8rem;

            > input {
                opacity: 0;
                position: absolute;

                + label {
                    height: 2.2rem;
                    width: 2.2rem;
                    display: block;
                    float: right;
                    cursor: pointer;
                    background-repeat: no-repeat;
                    background-size: contain;
                    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='22px' height='20px' viewBox='0 0 22 20' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EStar%3C/title%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cpolygon id='Star' fill='#{color(Gray, Gray-150)}' points='11 16.5 4.53436222 19.8991869 5.76918916 12.6995935 0.538378321 7.60081306 7.76718111 6.55040653 11 0 14.2328189 6.55040653 21.4616217 7.60081306 16.2308108 12.6995935 17.4656378 19.8991869'%3E%3C/polygon%3E%3C/g%3E%3C/svg%3E");
                }

                &:checked ~ label, & + label:hover ~ label, & + label:hover {
                    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='22px' height='20px' viewBox='0 0 22 20' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EStar%3C/title%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cpolygon id='Star' fill='#{color(Base, SecondaryColor)}' points='11 16.5 4.53436222 19.8991869 5.76918916 12.6995935 0.538378321 7.60081306 7.76718111 6.55040653 11 0 14.2328189 6.55040653 21.4616217 7.60081306 16.2308108 12.6995935 17.4656378 19.8991869'%3E%3C/polygon%3E%3C/g%3E%3C/svg%3E");
                }
            }
        }

        + .field-error {
            margin-top: 0;
        }
    }
}

.similarity-message {
    font-size: 1.4rem;
    margin: .5rem 0 0;

    > span {
        cursor: pointer;
        text-decoration: underline;
        color: color(Base, PrimaryColor);
    }
}

.field__container {
    label.file-upload {
        @include transition();
        @include border-radius(.8rem);
        overflow: hidden;
        position: relative;
        padding: 2.4rem;
        border: .1rem solid color(Gray, Gray-150);
        background-color: color(Gray, Gray-200);
        color: color(Gray, Gray-800);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        gap: 0;
        width: 100%;
        justify-content: center;

        @include breakpoint(medium) {
            width: 35rem;

            &.dropzone-dragging, &:hover {
                box-shadow: rgba(0, 0, 0, 0.1) 0 1rem 1.5rem -.3rem, rgba(0, 0, 0, 0.05) 0 .4rem .6rem -.2rem;
            }
        }

        .--overlay {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: color(Base, White);
            opacity: .2;
            z-index: 1;
        }

        .--content {
            @include border-radius(.8rem);
            padding: 2.4rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: .8rem;
            z-index: 2;
            background-color: rgba(255, 255, 255, .75);;

            .--image {
                display: block;
                width: 3.2rem;
                height: 3.2rem;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='#{color(Gray, Gray-800)}' class='size-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='m2.25 15.75 5.159-5.159a2.25 2.25 0 0 1 3.182 0l5.159 5.159m-1.5-1.5 1.409-1.409a2.25 2.25 0 0 1 3.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 0 0 1.5-1.5V6a1.5 1.5 0 0 0-1.5-1.5H3.75A1.5 1.5 0 0 0 2.25 6v12a1.5 1.5 0 0 0 1.5 1.5Zm10.5-11.25h.008v.008h-.008V8.25Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z' /%3E%3C/svg%3E%0A");
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center;
            }

            .--title {
                display: block;
            }

            small {
                font-size: 1.1rem;
                color: color(Gray, Gray-700);
                line-height: normal;
                margin: 0 0 .8rem;
            }
        }
    }
}
