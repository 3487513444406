.product__personalisation-container {
    @include breakpoint(medium) {
        width: 65%;
    }

    .product__personalisation {
        margin: 0 0 $grid__padding;

        ._title {
            font-size: 1.5rem;
            font-weight: 700;
            line-height: 2.3rem;
        }

        .flex {
            font-size: 1.5rem;
            line-height: 3rem;
            align-items: center;
            gap: .4rem;

            > span {
                display: flex;
                align-items: center;
                gap: .4rem;

                i {
                    flex: 0 0 $grid__padding;
                }
            }
        }

        > button {
            display: none;
        }

        .product__personalisation-detail {
            @include border-radius();
            background: color(Gray, Gray-200);
            padding: .8rem;
            margin: .8rem 0 2.4rem;

            > span {
                font-size: 1.4rem;
                font-weight: 600;
            }

            ._form {
                padding: .4rem 0 0;
                gap: $grid__padding;

                &.--2column {
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    grid-column-gap: .8rem;
                    grid-row-gap: 0;

                    @include breakpoint(small only) {
                        grid-template-columns: 1fr;
                    }
                }

                .field__group {
                    flex: 1;
                    gap: .4rem;
                    flex-direction: column;
                    margin: 0 0 .8rem;

                    .title__holder {
                        display: flex;
                        align-items: center;
                        gap: .4rem;
                        margin: 0 0 .8rem;

                        label {
                            font-size: 1.4rem;
                            color: color(Gray, Gray-900);
                            font-weight: 600;

                            span {
                                color: color(Gray, Gray-700);
                                font-size: 1.1rem;
                                position: relative;
                                top: .2rem;
                            }

                            button {
                                margin-left: .2rem;
                            }
                        }
                    }

                    .max__characters {
                        display: block;
                        font-size: 1.1rem;
                        color: color(Gray, Gray-700);
                    }

                    > button {
                        display: none;
                    }
                }

                &.--block {
                    display: block;
                }
            }
        }

        .--content {
            padding: $grid__padding;
        }
    }
}
