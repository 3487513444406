.footer__rating-container {
    background: color(Gray, Gray-200);
    padding: 3.2rem 0;

    @include breakpoint(medium) {
        padding: 9.6rem 0;

        @include tablet-only() {
            padding: 4.2rem 0;
        }
    }

    @include breakpoint(small only) {
        > .grid-container {
            padding-right: 0;
        }
    }

    .blaze-slider {
        --slide-gap: $grid__padding;
    }

    .--title {
        @include title-after();
        display: block;
        font-weight: 700;
        font-size: 2.4rem;
        line-height: 4rem;
        margin: 0 0 $grid__padding;

        @include breakpoint(medium) {
            font-size: 3.2rem;
            line-height: 4.8rem;
        }
    }

    .footer__rating-score {
        display: flex;
        flex-direction: column;
        gap: $grid__padding;
        align-items: center;

        .--score {
            font-weight: 600;
            font-size: 6rem;
            font-style: italic;
            line-height: 7.2rem;
        }

        p {
            text-align: center;
            font-size: 1.4rem;
            line-height: 1.6rem;
            max-width: 90%;
        }

        @include tablet-only() {
            .--button {
                font-size: 1.2rem;
            }
        }
    }

    .footer__rating-block {
        @include border-radius();
        position: relative;
        background-color: color(Base, White);
        filter: drop-shadow(.4rem .4rem 1.4rem rgba(0, 0, 0, .05));
        margin: 0 0 2.4rem;
        padding: 3.8rem $grid__padding $grid__padding;
        display: flex;
        flex-direction: column;
        background-image: url("data:image/svg+xml,%3Csvg width='54' height='32' viewBox='0 0 54 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M53.8118 0L51.506 3.8259L38.8547 25.2099C36.6243 28.9142 31.1215 31.949 26.4194 31.949H25.2756V32H0V3C0 1.34315 1.34315 0 3 0H53.8118Z' fill='black'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;

        &::after {
            position: absolute;
            content: '';
            display: block;
            top: 100%;
            left: $grid__padding;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: $grid__padding 2.4rem 0 0;
            border-color: color(Base, White) transparent transparent transparent;
            border-radius: 0 0 .3rem .3rem;
        }

        .--score {
            position: absolute;
            color: color(Base, White);
            font-size: 1.4rem;
            font-weight: 700;
            line-height: 2.2rem;
            top: .4rem;
            left: 1.4rem;
        }

        p {
            flex: 1;
            font-size: 1.4rem;
            line-height: 1.8rem;
            margin: 0 0 .8rem;
        }

        .--author, .--city {
            font-size: 1.3rem;
            line-height: 1.6rem;
            color: color(Gray, Gray-900);

            > span {
                font-weight: 700;
            }
        }
    }
}
