.product__label {
    z-index: 2;
    position: absolute;
    width: fit-content;
    height: 2.9rem;
    top: -.1rem;
    left: 0;
    display: flex;
    color: color(Base, White);
    font-size: 1.2rem;
    font-weight: 700;
    padding: .3rem 0 .3rem .6rem;
    background-color: color(Base, Black);
    line-height: normal;

    @include breakpoint(medium) {
        padding: .6rem 0 .6rem 1.2rem;
        height: 3.9rem;
        font-size: 1.4rem;
    }

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 100%;
        width: 2.5rem;
        height: 2.9rem;
        background-image: url("data:image/svg+xml,%3Csvg width='34' height='39' viewBox='0 0 34 39' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M27.506 10.8064L34 0.0241025L0 0V39H1.27562V38.949H2.41938C7.12153 38.949 12.6243 35.912 14.8546 32.2052L27.506 10.8064Z' fill='#{color(Base, Black)}'/%3E%3C/svg%3E%0A");
        background-size: cover;
        background-repeat: no-repeat;

        @include breakpoint(medium) {
            background-size: contain;
            width: 3.4rem;
            height: 3.9rem;
        }
    }

    &._green {
        background-color: color(Alert, Success);

        &::after {
            background-image: url("data:image/svg+xml,%3Csvg width='34' height='39' viewBox='0 0 34 39' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M27.506 10.8064L34 0.0241025L0 0V39H1.27562V38.949H2.41938C7.12153 38.949 12.6243 35.912 14.8546 32.2052L27.506 10.8064Z' fill='#{color(Alert, Success)}'/%3E%3C/svg%3E%0A");
        }
    }

    &._advice {
        background-color: color(Base, PrimaryColor);

        &::after {
            background-image: url("data:image/svg+xml,%3Csvg width='34' height='39' viewBox='0 0 34 39' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M27.506 10.8064L34 0.0241025L0 0V39H1.27562V38.949H2.41938C7.12153 38.949 12.6243 35.912 14.8546 32.2052L27.506 10.8064Z' fill='#{color(Base, PrimaryColor)}'/%3E%3C/svg%3E%0A");
        }
    }

    &._blue {
        background-color: #1D73DF;

        &::after {
            background-image: url("data:image/svg+xml,%3Csvg width='34' height='39' viewBox='0 0 34 39' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M27.506 10.8064L34 0.0241025L0 0V39H1.27562V38.949H2.41938C7.12153 38.949 12.6243 35.912 14.8546 32.2052L27.506 10.8064Z' fill='%231D73DF'/%3E%3C/svg%3E%0A");
        }
    }

    &._discount {
        background-color: #ef953b;

        &::after {
            background-image: url("data:image/svg+xml,%3Csvg width='34' height='39' viewBox='0 0 34 39' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M27.506 10.8064L34 0.0241025L0 0V39H1.27562V38.949H2.41938C7.12153 38.949 12.6243 35.912 14.8546 32.2052L27.506 10.8064Z' fill='%23ef953b'/%3E%3C/svg%3E%0A");
        }
    }
}

.sticker__wrapper {
    position: relative;

    .product__stickers {
        display: flex;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        .sticker__group {
            position: absolute;
            z-index: 4;
            display: flex;
            gap: 1rem;

            &.top_left {
                top: 0;
                left: 0;
            }

            &.top_right {
                top: 0;
                right: 0;
            }

            &.bottom_left {
                bottom: 0;
                left: 0;
            }

            &.bottom_right {
                bottom: 0;
                right: 0;
            }

            .--sticker {
                line-height: normal;
                width: fit-content;
                position: relative;
                font-size: 1.2rem;
                height: 2.9rem;
                padding: .3rem .6rem;
                display: flex;
                color: color(Base, White);
                font-weight: 700;
                align-items: center;

                &.--top_left {
                    padding: .6rem .1rem .6rem 1.2rem;

                    .--shape {
                        left: calc(100% - .1rem);
                    }
                }

                &.--top_right {
                    padding: .6rem 1.2rem .6rem .1rem;

                    .--shape {
                        right: calc(100% - .1rem);
                    }
                }

                &.--bottom_left {
                    padding: .6rem .1rem .6rem 1.2rem;

                    .--shape {
                        left: calc(100% - .1rem);
                    }
                }

                &.--bottom_right {
                    padding: .6rem 1.2rem .6rem .1rem;

                    .--shape {
                        right: calc(100% - .1rem);
                    }
                }

                &.--circle, &.--square {
                    aspect-ratio: 1;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: .5rem;
                    text-align: center;
                    max-width: 6.5rem;
                    overflow: hidden;
                }

                &.--circle {
                    border-radius: 50%;
                }

                .--shape {
                    position: absolute;
                    top: 0;

                    @include breakpoint(small only) {
                        display: flex;
                        width: 2.5rem;
                        height: 2.9rem;

                        svg {
                            width: 100%;
                            height: 100%;
                        }
                    }
                }

                @include breakpoint(medium) {
                    font-size: 1.4rem;
                    height: 3.9rem;
                    padding: .6rem 1.2rem;
                }
            }
        }
    }
}
