.category__quick-links {
    @include breakpoint(small only) {
        ._header {
            padding: 0 $grid__padding;
        }

        .blaze-container {
            padding: 0 0 0 $grid__padding;
        }
    }

    ._content {
        @include border-radius();
        overflow: hidden;
        display: flex;
        flex-direction: column;
        border: .1rem solid color(Gray, Gray-150);
        margin-bottom: .1rem;

        @include breakpoint(small only) {
            &:last-of-type {
                margin-right: $grid__padding;
            }
        }

        picture {
            display: flex;
        }

        > span {
            display: block;
            font-size: 1.4rem;
            font-weight: 700;
            text-align: center;
            line-height: 2.4rem;
            padding: 1.6rem;

            @include breakpoint(medium) {
                font-size: 1.6rem;
            }
        }
    }

    .blaze-pagination {
        margin: .8rem 0 $grid__padding;
    }
}
