.store__selector {
    position: relative;
    margin-right: 3rem;

    @include tablet-only() {
        margin-right: 0;
    }

    > span {
        @include transition();
        cursor: pointer;
        font-size: 1.4rem;
        line-height: 2.2rem;
        padding: $grid__padding 4.5rem $grid__padding $grid__padding;
        background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='mask0_0_156' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='24' height='24'%3E%3Crect x='24' y='24' width='24' height='24' transform='rotate(-180 24 24)' fill='%23D9D9D9'/%3E%3C/mask%3E%3Cg mask='url(%23mask0_0_156)'%3E%3Cpath d='M16.6 8.96158L17.6538 10.0154L12 15.6692L6.34618 10.0154L7.4 8.96157L12 13.5616L16.6 8.96158Z' fill='black'/%3E%3C/g%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        background-position: right 1.4rem center;
        display: block;

        @include tablet-only() {
            font-size: 1.2rem;
        }
    }

    > div {
        position: absolute;
        top: 100%;
        left: 0;
        background-color: color(Gray, Gray-200);
        padding: 1.2rem;
        display: none;

        ul {
            display: flex;
            flex-direction: column;
            gap: .4rem;

            li {
                a {
                    display: flex;
                    background-color: color(Base, White);
                    gap: 1.4rem;
                    align-items: center;
                    font-size: 1.4rem;
                    line-height: 2.4rem;
                    background-repeat: no-repeat;
                    background-position: right 1.4rem center;
                    background-image: url("data:image/svg+xml,%3Csvg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='mask0_632_6781' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='24' height='25'%3E%3Crect y='0.875' width='24' height='24' fill='%23D9D9D9'/%3E%3C/mask%3E%3Cg mask='url(%23mask0_632_6781)'%3E%3Cpath d='M12.9462 12.875L8.34619 8.27502L9.40002 7.22119L15.0538 12.875L9.40002 18.5288L8.34619 17.475L12.9462 12.875Z' fill='black'/%3E%3C/g%3E%3C/svg%3E%0A");
                    padding-right: 12rem;
                }

                > span {
                    display: flex;
                    background-color: color(Base, White);
                    justify-content: center;
                    align-items: center;
                    height: 5.4rem;
                    font-size: 1.4rem;
                    line-height: 2.4rem;
                    gap: .4rem;
                }
            }
        }
    }

    @include hover() {
        > span {
            background-color: color(Gray, Gray-200);
            text-decoration: underline;
            background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='mask0_733_19831' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='24' height='24'%3E%3Crect width='24' height='24' fill='%23D9D9D9'/%3E%3C/mask%3E%3Cg mask='url(%23mask0_733_19831)'%3E%3Cpath d='M7.40002 15.0384L6.34619 13.9846L12 8.33081L17.6538 13.9846L16.6 15.0384L12 10.4384L7.40002 15.0384Z' fill='black'/%3E%3C/g%3E%3C/svg%3E%0A");
        }

        > div {
            display: block;
        }
    }
}
