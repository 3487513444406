@font-face {
    font-family: 'Titillium Web';
    src: url('../assets/fonts/TitilliumWeb-Light.woff2') format('woff2'),
    url('../assets/fonts/TitilliumWeb-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: fallback;
}

@font-face {
    font-family: 'Titillium Web';
    src: url('../assets/fonts/TitilliumWeb-Regular.woff2') format('woff2'),
    url('../assets/fonts/TitilliumWeb-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: fallback;
}

@font-face {
    font-family: 'Titillium Web';
    src: url('../assets/fonts/TitilliumWeb-Bold.woff2') format('woff2'),
    url('../assets/fonts/TitilliumWeb-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: fallback;
}

@font-face {
    font-family: 'Titillium Web';
    src: url('../assets/fonts/TitilliumWeb-SemiBold.woff2') format('woff2'),
    url('../assets/fonts/TitilliumWeb-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: fallback;
}
