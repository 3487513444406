.catalog__category-placeholder {
    @include breakpoint(medium) {
        margin: $grid__padding 0 0;
    }

    @include breakpoint(small only) {
        margin: 0 rem-calc(-$grid__padding);
    }

    ul {
        padding: .1rem 0 0 .1rem;
    }

    .inline__filter-container {
        ._options label {
            background: color(Base, PlaceHolder);
            height: 4rem;
            width: 8rem;
        }
    }

    ._placeholder {
        display: block;
        background: color(Base, PlaceHolder);
        aspect-ratio: 311/428;
        margin: -.1rem 0 0 -.1rem;

        &.--border {
            border: .1rem solid color(Gray, Gray-150);
        }
    }
}

.catalog__category-filter-placeholder {
    span {
        display: block;
        background: color(Base, PlaceHolder);
        height: 20rem;
        margin: 0 0 3.2rem;
    }
}
