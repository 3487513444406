@use 'sass:math';

/* Override default */
$grid__max-width: 129.4rem;
$grid__gutter: 3.2rem;
$grid__max-padding: 3.2rem;
$grid__padding: math.div($grid__gutter, 2);

$grid__breakpoints: (
        '': 0,
        small: 576,
        medium: 768,
        between-medium-small: 941,
        large: 1025,
        between-large-medium: 1100,
        xlarge: 1200,
        xxlarge: 1440,
) !default;

$color-palette: (
        Base: (
                White: rgba(255, 255, 255, 1), // #FFFFFF
                Black: rgba(0, 0, 0, 1), // #000000
                PrimaryColor: rgba(252, 15, 64, 1), // #FC0F40
                SecondaryColor: rgba(212, 175, 55, 1), // #D4AF37
                PlaceHolder: rgba(251, 251, 251, 1), // #FBFBFB
        ),
        Gray: (
                Gray-100: rgba(217, 217, 217, 1), // #D9D9D9
                Gray-150: rgba(227, 227, 227, 1), // #E3E3E3
                Gray-200: rgba(245, 245, 245, 1), // #F5F5F5
                Gray-300: rgba(238, 238, 238, 1), // #EEEEEE
                Gray-400: rgba(224, 224, 224, 1), // #E0E0E0
                Gray-500: rgba(189, 189, 189, 1), // #BDBDBD
                Gray-600: rgba(158, 158, 158, 1), // #9E9E9E
                Gray-700: rgba(124, 124, 124, 1), // #7C7C7C
                Gray-800: rgba(95, 95, 95, 1), // #5F5F5F
                Gray-900: rgba(78, 78, 78, 1), // #4E4E4E
                Gray-1000: rgba(33, 33, 33, 1), // #212121
        ),
        Alert: (
                Error: rgba(189, 37, 44, 1), // #BD252C
                Warning: rgba(233, 115, 12, 1), // #E9730C
                Success: rgba(2, 128, 2, 1), // #028002
                Neutral: rgb(32, 62, 136), // #203E88
        ),
        AlertBackgrounds: (
                Error: rgba(250, 231, 232, 1), // #FAE7E8
                Warning: rgba(255, 250, 245, 1), // #FFFAF5
                Success: rgba(242, 255, 242, 1), // #F2FFF2
                Neutral: rgb(221, 236, 255) // #DDECFF,
        ),
);
