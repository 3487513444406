.blog__widget {
    background: color(Base, Black);
    padding: 5.6rem 0;

    @include breakpoint(small only) {
        padding: $grid__padding 0 $grid__padding $grid__padding;
    }

    .home__blog-list__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: $grid__padding;
        margin: $grid__padding 0;

        @include breakpoint(small only) {
            padding-right: $grid__padding;
        }

        h2 {
            @include title-after();
            color: color(Base, White);
        }
    }

    .home__blog-list__item {
        @include border-radius();
        overflow: hidden;
        background-color: color(Base, White);
        display: flex;
        flex-direction: column;

        .image__holder {
            display: flex;
            position: relative;

            a:not(.--category) {
                display: flex;
                width: 100%;

                img {
                    height: 22.5rem;
                    width: 100%;
                    object-fit: cover;
                }
            }

            .--category {
                @include transition();
                display: flex;
                align-items: center;
                justify-content: center;
                background: linear-gradient(90deg,
                        rgba(0, 0, 0, 0) 0%,
                        rgba(0, 0, 0, 0) 1rem,
                        rgba(255, 255, 255, 1) 1rem,
                        rgba(255, 255, 255, 1) calc(100% - 1rem),
                        rgba(0, 0, 0, 0) calc(100% - 1rem),
                        rgba(0, 0, 0, 0) 100%);
                font-size: 1.4rem;
                font-weight: 700;
                gap: 1rem;

                &::before {
                    content: '';
                    width: 1.1rem;
                    height: 2.8rem;
                    background-image: url("data:image/svg+xml,%3Csvg width='11' height='28' viewBox='0 0 11 28' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M10.2196 28C8.79011 27.9909 7.47327 27.2193 6.76683 25.975L1.08923 15.975C0.39384 14.7502 0.393841 13.2499 1.08923 12.0251L6.76683 2.02514C7.4775 0.773432 8.80589 7.34093e-05 10.2453 7.19174e-05L10.5662 0V28H10.2196Z' fill='white'/%3E%3C/svg%3E%0A");
                }

                &::after {
                    content: '';
                    width: 1.1rem;
                    height: 2.8rem;
                    background-image: url("data:image/svg+xml,%3Csvg width='11' height='28' viewBox='0 0 11 28' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.914299 -8.43798e-07C2.3438 0.00913357 3.66064 0.780723 4.36708 2.02499L10.0447 12.025C10.7401 13.2498 10.7401 14.7501 10.0447 15.9749L4.36708 25.9749C3.6564 27.2266 2.32801 27.9999 0.888624 27.9999L0.567688 28L0.567691 -8.74099e-07L0.914299 -8.43798e-07Z' fill='white'/%3E%3C/svg%3E%0A");
                }

                @include hover() {
                    background: linear-gradient(90deg,
                            rgba(0, 0, 0, 0) 0%,
                            rgba(0, 0, 0, 0) 1rem,
                            color(Base, PrimaryColor) 1rem,
                            color(Base, PrimaryColor) calc(100% - 1rem),
                            rgba(0, 0, 0, 0) calc(100% - 1rem),
                            rgba(0, 0, 0, 0) 100%);
                    color: color(Base, White);

                    &::before {
                        background-image: url("data:image/svg+xml,%3Csvg width='11' height='28' viewBox='0 0 11 28' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M10.2196 28C8.79011 27.9909 7.47327 27.2193 6.76683 25.975L1.08923 15.975C0.39384 14.7502 0.393841 13.2499 1.08923 12.0251L6.76683 2.02514C7.4775 0.773432 8.80589 7.34093e-05 10.2453 7.19174e-05L10.5662 0V28H10.2196Z' fill='#{color(Base, PrimaryColor)}'/%3E%3C/svg%3E%0A");
                    }

                    &::after {
                        background-image: url("data:image/svg+xml,%3Csvg width='11' height='28' viewBox='0 0 11 28' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.914299 -8.43798e-07C2.3438 0.00913357 3.66064 0.780723 4.36708 2.02499L10.0447 12.025C10.7401 13.2498 10.7401 14.7501 10.0447 15.9749L4.36708 25.9749C3.6564 27.2266 2.32801 27.9999 0.888624 27.9999L0.567688 28L0.567691 -8.74099e-07L0.914299 -8.43798e-07Z' fill='#{color(Base, PrimaryColor)}'/%3E%3C/svg%3E%0A");
                    }
                }
            }
        }

        > a {
            display: flex;
            flex-direction: column;
            padding: 2.4rem 3.6rem;
            flex: 1;
            gap: $grid__padding;

            @include breakpoint(medium) {
                gap: 2.4rem;
            }

            @media screen and (max-width: 1200px) {
                padding: $grid__padding;
            }

            h4 {
                @include transition();
                font-size: 2.4rem;
                line-height: 2.8rem;
                font-weight: 700;
                flex: 1;
                max-height: 5.6rem;
                overflow: hidden;
            }

            > span {
                @include transition();
                margin-top: auto;
                font-weight: 700;
            }
        }

        @include hover() {
            > a h4, > a > span {
                text-decoration: underline;
            }
        }
        &__category-wrapper{
            position: absolute;
            border: 0;
            display: flex;
            gap: 0.6rem;
            bottom: 8px;
            left: 8px;
        }
    }

    .blaze-slider > a {
        margin: $grid__padding 0;
    }
}
