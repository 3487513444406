.brands__widget {
    .brands__widget-slider {
        .blaze-slider {
            --slides-to-show: 3.3;

            @include breakpoint(medium) {
                --slides-to-show: 8;
            }
        }

        .brands__widget-slider__header {
            display: flex;
            align-items: center;
            gap: $grid__padding;
            margin: $grid__padding 0;

            h2 {
                @include title-after();
            }

            .blaze-controls {
                margin-left: auto;
            }
        }

        .blaze-track {
            align-items: center;

            .slider__slide {
                a {
                    display: flex;
                    justify-content: center;
                    padding: $grid__padding;
                }
            }
        }
    }
}
