.page__builder--text-image-wrapper {
    .cms-page {
        @include breakpoint(small only) {
            padding: 2.4rem 0;
        }

        h3 {
            margin: 0 0 $grid__padding;
            font-size: 2rem;
            line-height: 2.4rem;

            @include breakpoint(small only) {
                @include title-after();
            }
        }

        .ul, ol {
            list-style: disc outside;
        }
    }

    .--image {
        @include border-radius();
        overflow: hidden;
        position: relative;

        picture {
            display: flex;
        }

        > a {
            display: block;
            position: absolute;
            bottom: 2.4rem;
            left: 50%;
            transform: translateX(-50%);
            white-space: nowrap;
        }
    }
}
