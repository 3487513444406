body.modal__open {
    height: 100vh;
    overflow-y: hidden;
}

.popup__holder {
    @include popup();

    &._small {
        .popup__holder-container {
            min-width: 25%;
            @include breakpoint(small only) {
                min-width: 80%;
            }
        }
    }

    &._out-of-stock {
        ._title {
            background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='11px' height='12px' viewBox='0 0 11 12' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EShape%3C/title%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cpath d='M10.92,7.98 C10.92,8.67542027 10.3544629,9.24 9.65792243,9.24 L1.26207757,9.24 C0.565093137,9.24 0,8.67618083 0,7.98 C0,7.28476308 0.56461944,6.72 1.26043825,6.72 C1.25962089,6.72 1.26,4.19984237 1.26,4.19984237 C1.26,1.87973033 3.14011517,0 5.46,0 C7.77990041,0 9.66,1.88017891 9.66,4.19984237 L9.66,6.71963391 C10.3543424,6.72 10.92,7.28454395 10.92,7.98 Z M9.65956175,7.56 C9.19542599,7.56 8.82,7.18475629 8.82,6.71963391 L8.82,4.19984237 C8.82,2.34409016 7.31597328,0.84 5.46,0.84 C3.60399587,0.84 2.1,2.34368801 2.1,4.19984237 L2.1,6.71963391 C2.1,7.18342433 1.7236557,7.56 1.26043825,7.56 C1.02858647,7.56 0.84,7.74863445 0.84,7.98 C0.84,8.2118367 1.02858846,8.4 1.26207757,8.4 L9.65792243,8.4 C9.89086201,8.4 10.08,8.2111822 10.08,7.98 C10.08,7.74883412 9.89079336,7.56 9.65956175,7.56 Z M3.57,9.87 L4.41,9.87 C4.41,10.449899 4.88010102,10.92 5.46,10.92 C6.03989898,10.92 6.51,10.449899 6.51,9.87 L7.35,9.87 C7.35,10.9138182 6.50381817,11.76 5.46,11.76 C4.41618183,11.76 3.57,10.9138182 3.57,9.87 Z' id='Shape' fill='%23333333' fill-rule='nonzero'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
            padding-left: 2.2rem;
            background-size: 1.2rem;
        }

        ._content {
            a {
                text-decoration: underline;
            }

            .field__group {
                width: 80%;

                label {
                    font-size: 1.5rem;
                    line-height: 2rem;
                    font-weight: 600;
                    color: color(Gray, Gray-600);
                    margin: 0 0 .5rem;
                }
            }

            ._button {
                margin: .8rem 0 0;
            }
        }
    }
}
