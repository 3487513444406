.--anonymous_body {
    .breadcrumbs ._sep {
        display: none;
    }
}

.catalog-category-page {
    .catalog__category {
        .category__header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: $grid__padding;

            h1 {
                @include title-after();
            }

            .catalog__category-sorter {
                @include hexagon-input('gray');
                display: flex;
                align-items: center;

                span {
                    font-weight: 700;
                    white-space: nowrap;
                    line-height: 4.4rem;
                    font-size: 1.4rem;
                    padding-left: 1rem;

                    @include tablet-only() {
                        display: none;
                    }
                }

                select {
                    padding-left: .4rem;
                }
            }
        }

        .catalog__category-product_count {
            font-size: 1.6rem;
            line-height: 1;
            @include breakpoint(small only) {
                margin-bottom: 1.6rem;
            }
        }

        .catalog__category-description--top {
            margin: $grid__padding 0 0;
        }

        .toolbar__active-filters {
            margin: $grid__padding rem-calc(-$grid__padding) 0;

            @include breakpoint(medium) {
                margin: $grid__padding 0 0;
                display: flex;
                gap: .8rem;
                align-items: center;
            }

            > span {
                font-size: 1.4rem;
                font-weight: 600;
                line-height: 2rem;
            }

            ul {
                margin-top: .8rem;

                @include breakpoint(medium) {
                    display: flex;
                    align-items: center;
                    gap: .8rem;
                    margin: 0;
                }

                li {
                    a, label {
                        @include transition();
                        background-color: color(Gray, Gray-200);
                        display: flex;
                        cursor: pointer;
                        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='currentColor' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M6 18 18 6M6 6l12 12' /%3E%3C/svg%3E%0A");
                        background-repeat: no-repeat;
                        padding: 1.2rem 3.2rem 1.2rem $grid__padding;
                        border-bottom: .1rem solid color(Base, White);
                        font-size: 1.6rem;
                        background-position: right 1.2rem center;
                        background-size: 2.2rem;

                        @include breakpoint(medium) {
                            @include border-radius();
                            padding: .4rem 2.2rem .4rem .8rem;
                            font-size: 1.4rem;
                            background-size: 1.4rem;
                            background-position: right .4rem center;
                            border: none;
                            line-height: 2.4rem;
                        }

                        @include hover() {
                            background-color: color(Gray, Gray-150);
                        }
                    }
                }
            }
        }

        .catalog__category-toolbar {
            position:sticky;
            top:10.4rem;
            width: 100%;

            &.--top {
                z-index: 9;
            }

            .toolbar-small{
                padding-left: 0 !important;
                padding-right: 0 !important;
            }

            .toolbar__top{
                background-color:white;
            }

            .toolbar__top-container {
                display: grid;
                grid-template-columns: 1fr 1fr;
                margin: 0;
                left:0;

                button {
                    background-color: color(Base, White);
                    border: .1rem solid color(Gray, Gray-150);
                    font-size: 1.6rem;
                    background-repeat: no-repeat;
                    background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7 3V7M17 10V14M7 17V21M3 5H21M3 12H21M3 19H21' stroke='black' stroke-width='1.5' stroke-linecap='square'/%3E%3C/svg%3E%0A");
                    background-position: left 1.5rem center;
                    padding: 0 1.5rem 0 5.2rem;
                    border-radius: .3rem 0 0 .3rem;
                    display: flex;
                    align-items: center;

                    > span {
                        margin-left: auto;
                        background-color: color(Base, SecondaryColor);
                        display: flex;
                        width: 2.4rem;
                        height: 2.4rem;
                        align-items: center;
                        justify-content: center;
                        border-radius: 50%;
                        font-size: 1.2rem;
                    }
                }

                &.--page-builder {
                    button {
                        border: .1rem solid color(Gray, Gray-150);
                        line-height: 4.4rem;
                    }
                }

                .catalog__category-sorter {
                    select {
                        white-space: nowrap;
                        font-size: 1.6rem;
                        background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.73721 5H13.9067V6.75403H1.73721V5ZM1.73721 9.0516H12.5544V10.7489H1.73721V9.0516ZM1.73721 13.1032H11.2022V14.7437H1.73721V13.1032ZM1.73721 16.5H9.84992V18.2596H1.73721V16.5ZM15.9494 5H17.5V17.5234H15.9494V5Z' fill='black'/%3E%3Cpath d='M16.6568 17.1465L13.31 13.5L12.6 14.2736L15.9475 17.9202L16.6576 18.6938L17.3676 17.9202L20.7135 14.2736L20.0035 13.5L16.6568 17.1465Z' fill='black'/%3E%3C/svg%3E%0A");
                        background-position: left 1.5rem center;
                        padding: 0 1.5rem 0 5.2rem;
                        border-radius: 0 .3rem .3rem 0;
                        border-left: 0;
                    }
                }
            }
        }
    }

    .page-builder__wrapper {
        margin: 0;

        &.--hidden {
            display: none;
        }

        ._header {
            margin-bottom: .8rem;
        }

        .page-builder__content-block {
            margin: 0;
        }
    }

    .bottom__widgets {
        border-top: .1rem solid color(Gray, Gray-150);
        padding: $grid__gutter 0;
        margin: 0;

        @include breakpoint(medium) {
            margin: 5.4rem 0 0;
            padding: 6.4rem 0 3.2rem;
        }
    }

    .catalog__category-description--bottom {
        background-color: color(Gray, Gray-200);
        padding: $grid__padding 0;

        @include breakpoint(medium) {
            padding: $grid__gutter 0;
            margin: 5.4rem 0 0;
        }

        h1, h2, h3, h4 {
            margin: 3rem 0 1rem;
        }

        > .small-24 > *:first-child {
            margin-top: 0;
        }
    }
}
