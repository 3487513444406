.checkout-cart_body {
    @include breakpoint(small only) {
        header {
            .search-form__holder {
                display: none;
            }
        }
    }
}

.checkout-cart {
    @include breakpoint(small only) {
        margin-bottom: 20px;
    }

    .cart-container {
        margin: 0 0 $grid__padding;

        h3 {
            font-size: 2rem;
            line-height: 2.4rem;
        }

        &.free-article {
            @include border-radius();
            background: color(Gray, Gray-200);
            border: .1rem solid color(Gray, Gray-300);
            padding: $grid__padding;

            .cart__item {
                .cart__image {
                    width: 100%;
                    @include border-radius();
                    @include background-image-logo();
                    background-color: color(Base, White);
                    border: .1rem solid color(Gray, Gray-300);
                    padding: 0;
                    aspect-ratio: 1;
                }

                .cart__item__information {
                    > span {
                        font-size: 1.6rem;
                        line-height: 2rem;
                    }

                    .free-article__product-options {
                        margin: 10px 0 0;

                        li {
                            margin: 0 0 10px;
                        }
                    }
                }
            }
        }
    }

    .breadcrumbs {
        display: none;
    }

    .page-wrapper {
        padding: 30px 0 0;
        margin: 0 auto;
        @include breakpoint(small only) {
            padding: 15px 0 0;
        }

        .cart-header {
            margin-bottom: 20px;
            @include breakpoint(small only) {
                margin-bottom: 15px;
            }

            h1 {
                font-size: 25px;
                line-height: 46px;
                @include breakpoint(small only) {
                    font-size: 20px;
                    line-height: 42px;
                }
            }

            .header-container {
                display: flex;

                button {
                    width: 100%;
                    @include breakpoint(medium) {
                        margin-left: auto;
                        width: auto;
                    }
                    @include breakpoint(small only) {
                        margin-top: 5px;
                        text-align: center;
                    }
                }
            }
        }

        .cart__message {
            padding: 5px 10px;
            border-radius: 2px;
            display: block;
            margin: 0 0 15px;
        }
    }

    .cart-holder {
        .cart__item {
            border-bottom: 0.1rem solid color(Gray, Gray-300);
            padding: 10px 0;
            @include breakpoint(small only) {
                position: relative;
            }

            &._first {
                border-top: 0.1rem solid color(Gray, Gray-300);
            }

            .cart__image {
                @include background-image-logo();
                background-size: 25%;
                display: inline-block;
                padding: 10px 0;
                aspect-ratio: 1;
            }

            .cart__item__information {
                @include breakpoint(small only) {
                    padding-right: 8.8rem;
                }
                @include breakpoint(medium up) {
                    padding: 5px 0;
                }

                a {
                    display: inline-block;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 20px;
                    margin: 10px 0 5px;
                    color: color(Base, Black);

                    @include breakpoint(small only) {
                        font-size: 14px;
                        line-height: 16px;
                    }

                    &:hover {
                        text-decoration: none;
                    }
                }

                ul {
                    font-size: 15px;
                    line-height: 20px;
                    font-weight: 400;
                    color: color(Base, Black);

                    span {
                        color: color(Gray, Gray-600);
                        display: inline-block;
                        min-width: 37px;

                        &.--semi-bold {
                            font-weight: 600;
                            color: color(Base, Black);
                            margin: .4rem 0 0;
                        }
                    }

                    @include breakpoint(small only) {
                        font-size: 14px;
                        line-height: 16px;
                    }

                    .bundle__options {
                        display: flex;
                        flex-direction: column;
                        gap: .4rem;
                        margin-top: .4rem;
                    }
                }

                .--delivery {
                    margin-top: .8rem;
                }
            }

            .cart__item__qty {
                @include breakpoint(medium) {
                    padding: 10px 0;
                }

                select {
                    vertical-align: top;
                    height: 44px;
                    color: color(Base, Black);
                    line-height: 40px;
                    border: 2px solid color(Gray, Gray-300);

                    @include breakpoint(between-medium-small) {
                        width: 40%;
                    }

                    @include breakpoint(small only) {
                        width: 50%;
                        height: 40px;
                        line-height: 36px;
                        max-width: 100px;
                    }
                }

                button {
                    cursor: pointer;
                    line-height: 22px;
                    margin: 10px 0 10px 15px;
                    color: color(Base, Black);
                    font-size: 1.4rem;

                    @include breakpoint(small only) {
                        margin-left: 10px;
                    }

                    &:not(:disabled) {
                        @include hover() {
                            text-decoration: underline;
                        }
                    }

                    &:disabled {
                        cursor: not-allowed;
                        color: color(Gray, Gray-700);
                    }
                }

                .--delivery {
                    margin-top: $grid__padding;
                }
            }

            .row__price {
                display: block;
                line-height: 44px;
                font-size: 16px;
                font-weight: 700;
                color: color(Base, Black);
                text-align: right;

                .--loading {
                    margin: .95rem 0;
                    justify-content: flex-end;
                }

                ._free {
                    color: color(Alert, Success);
                    font-weight: normal;
                }

                @include breakpoint(small only) {
                    position: absolute;
                    top: 19px;
                    right: 0;
                    font-size: 15px;
                    line-height: 18px;
                }
                @include breakpoint(medium up) {
                    padding: 10px 0;
                }


                .price__old-wrapper{
                    display: flex;
                    justify-content: end;
                    line-height: normal;
                    gap:.4rem;
                    .price__old-label{
                        font-weight: 500;
                        font-size:1.3rem;
                        color: color(Gray, Gray-700);
                        @include breakpoint(small only) {
                            font-size: 1.1rem;
                            line-height: 2rem;
                        }
                    }
                }

                .price__old-price {
                    font-weight: 600;
                    color: color(Gray, Gray-700);
                    font-size: 13px;
                    position: relative;

                    @include breakpoint(medium up) {
                        top: 1px;
                        margin-right: 5px;
                    }

                    @include breakpoint(small only) {
                        display: block;
                    }
                }

                ._loading {
                    margin: 5px 0;
                }
            }
        }
    }

    .cta__services {
        span {
            @include breakpoint(medium down) {
                max-width: 250px;
            }
            @include breakpoint(small only) {
                padding-left: 45px;
                &._customer-service {
                    margin-top: 10px;
                }
            }
        }
    }

    .order_comment {
        .cart__field-controls {
            ul {
                li {
                    display: flex;
                    padding: 10px 0;

                    > label {
                        font-size: 15px;
                        line-height: 20px;
                        font-weight: 600;
                    }

                    .vue-js-switch {
                        margin-left: auto;
                    }
                }
            }
        }
    }

    .totals-container {
        @include border-radius();
        background: color(Gray, Gray-200);
        border: .1rem solid color(Gray, Gray-300);
        padding: $grid__padding;
        margin-top: 10px;

        .--loading:not(.--button) {
            margin: $grid__padding 0 $grid__gutter;
        }

        @include breakpoint(medium only) {
            margin: 0 0 10px;
        }

        @include breakpoint(small only) {
            margin: 0 -15px 20px;
        }

        li {
            display: flex;

            span {
                line-height: 50px;
                font-size: 17px;
                font-weight: 300;
                color: color(Gray, Gray-700);

                @include breakpoint(small only) {
                    font-size: 15px;
                    line-height: 40px;
                }

                &._free {
                    span {
                        color: color(Alert, Success);
                    }
                }
            }

            &._grand {
                span {
                    color: color(Base, Black);

                    @include breakpoint(medium) {
                        font-size: 18px;
                    }
                }

                ._grand {
                    font-weight: 700;
                }
            }

            &._small {
                span {
                    font-size: 14px;
                    line-height: 30px;
                }
            }

            span:last-child {
                text-align: right;
                margin-left: auto;
                font-weight: 400;
            }
        }

        ._actions {
            display: flex;
            flex-direction: column;
            gap: $grid__padding;
            margin: $grid__padding 0 0;

            .--button {
                width: 100%;
            }

            a {
                padding: 1rem 1.5rem 1rem 1.5rem;
                font-size: 1.4rem;
            }
        }
    }

    .toggle-coupon, .toggle-comment {
        display: flex;
        justify-content: flex-end;
        margin: 15px 0 0;
        align-items: center;

        label {
            cursor: pointer;
            font-size: 15px;
            line-height: 20px;
            margin-left: 10px;
        }
    }

    .coupon-code__container {
        @include breakpoint(small only) {
            padding: 20px 0;
            width: 100%;
        }

        ._loading {
            @include loading;
            margin: 10px 0 0 auto;
            display: flex;
        }

        .coupon-code__form {
            padding: 10px 0 0;
            display: flex;
            flex-wrap: wrap;
            align-items: center;

            > label {
                width: 100%;
                text-align: right;
                margin: 0 0 5px;
                font-size: 1.4rem;
                line-height: 2rem;
                color: color(Gray, Gray-900);
            }

            .field-success, .field-error {
                font-size: 14px;
                margin: 5px 0 0;
                display: flex;
                line-height: 16px;
                color: color(Alert, Success);

                &.field-error {
                    color: color(Alert, Error);
                    text-align: right;
                    display: block;
                    width: 100%;
                }
            }

            .form-holder {
                display: flex;
                margin-left: auto;
                align-items: center;
                gap: .8rem;

                input {
                    width: initial;
                    flex: 1;
                }

                @include breakpoint(medium) {
                    width: 80%;
                }
            }

            > button {
                @include default-button();
                margin-left: auto;
                margin-top: $grid__padding;
                display: inline-flex;
            }
        }
    }

    .cart-empty {
        p {
            line-height: 22px;

            a {
                text-decoration: underline;
            }
        }
    }

    @include breakpoint(small only) {
        .global__usp-container {
            text-align: center;
        }
    }

    ._button._cta._loading {
        width: 100%;
        height: auto;
        background-image: none;

        &:after {
            content: '';
            background-image: url("data:image/svg+xml,%3Csvg version='1.1' id='loader-1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='40px' height='40px' viewBox='0 0 50 50' style='enable-background:new 0 0 50 50;' xml:space='preserve'%3E%3Cpath fill='%23000' fill='#{color(Base, White)}' d='M25.251,6.461c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615V6.461z'%3E%3CanimateTransform attributeType='xml' attributeName='transform' type='rotate' from='0 25 25' to='360 25 25' dur='0.6s' repeatCount='indefinite'/%3E%3C/path%3E%3C/svg%3E");
            background-size: cover;
            margin-left: 5px;
            width: 20px;
            height: 20px;
            top: 4px;
            position: relative;
            display: inline-block;
        }
    }

    .crosssell_container {
        @include breakpoint(small only) {
            padding-left: $grid__padding;
        }

        .--header {
            display: flex;
            align-items: center;
            margin: $grid__padding 0;

            @include breakpoint(small only) {
                padding: 0 $grid__padding 0 0;
            }

            h2 {
                @include title-after();
            }

            .blaze-controls {
                margin-left: auto;
            }
        }
    }
}
