.pagination {
    display: flex;
    justify-content: center;
    margin: $grid__padding 0;

    @include breakpoint(medium) {
        margin: 5.6rem 0;
    }

    ul {
        display: flex;
        align-items: center;
        gap: 1.2rem;

        @include breakpoint(small only){
            flex-wrap: wrap;
            justify-content: center;
        }

        li {
            span, a {
                font-size: 1.6rem;
                line-height: 2.4rem;
                font-weight: 700;

                &._current {
                    width: 3.2rem;
                    height: 3.2rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-image: url("data:image/svg+xml,%3Csvg width='32' height='28' viewBox='0 0 32 28' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M31.134 12.5C31.6699 13.4282 31.6699 14.5718 31.134 15.5L24.866 26.3564C24.3301 27.2846 23.3397 27.8564 22.2679 27.8564L9.73205 27.8564C8.66025 27.8564 7.66987 27.2846 7.13397 26.3564L0.866027 15.5C0.330129 14.5718 0.330129 13.4282 0.866027 12.5L7.13398 1.64359C7.66987 0.71539 8.66026 0.143593 9.73205 0.143593L22.2679 0.143593C23.3397 0.143593 24.3301 0.71539 24.866 1.64359L31.134 12.5Z' fill='%23D4AF37'/%3E%3C/svg%3E%0A");
                    color: color(Base, White);
                    background-repeat: no-repeat;
                    background-position: center;
                }

                &._no-pointer {
                    margin: 0 1.2rem;
                }

                &._next {
                    background-repeat: no-repeat;
                    background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='mask0_471_41626' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='24' height='24'%3E%3Crect width='24' height='24' fill='%23D9D9D9'/%3E%3C/mask%3E%3Cg mask='url(%23mask0_471_41626)'%3E%3Cpath d='M12.9462 12L8.34616 7.40002L9.39999 6.34619L15.0538 12L9.39999 17.6538L8.34616 16.6L12.9462 12Z' fill='%2342423D'/%3E%3C/g%3E%3C/svg%3E%0A");
                    background-position: right center;
                    padding-right: 2.6rem;
                    margin-left: 1.2rem;
                }

                &._prev {
                    background-repeat: no-repeat;
                    background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='mask0_471_41626' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='24' height='24'%3E%3Crect width='24' height='24' fill='%23D9D9D9'/%3E%3C/mask%3E%3Cg mask='url(%23mask0_471_41626)'%3E%3Cpath d='M11.0538 12L15.6538 16.6L14.6 17.6538L8.94616 12L14.6 6.34619L15.6538 7.40002L11.0538 12Z' fill='%2342423D'/%3E%3C/g%3E%3C/svg%3E%0A");
                    background-position: left center;
                    padding-left: 2.6rem;
                    margin-right: 1.2rem;
                }

                @include hover() {
                    &:is(a) {
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}
