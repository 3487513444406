.promotion__block {
    .category__header-image {
        @include border-radius();
        overflow: hidden;
        position: relative;

        @include breakpoint(medium) {
            height: 100%;
        }

        picture {
            display: flex;

            @include breakpoint(medium) {
                height: 100%;

                img {
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }
            }
        }

        ._content {
            position: absolute;
            top: $grid__padding;
            left: $grid__padding;
            right: $grid__padding;
            bottom: $grid__padding;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            gap: .4rem;

            @include breakpoint(medium) {
                top: auto;
                bottom: 20%;
            }

            ._sub-title {
                font-size: clamp(1.4rem, 0.4rem + 1.6vi, 2rem);
                text-transform: uppercase;
                font-weight: 600;
            }

            ._title {
                font-size: clamp(2.4rem, -1rem + 6vi, 5rem);
                font-weight: 700;
                text-transform: uppercase;
            }

            &._white {
                ._sub-title, ._title {
                    @include text-shadow();
                    color: color(Base, White);
                }
            }

            &._black {
                ._sub-title, ._title {
                    color: color(Base, Black);
                }
            }

            ._button {
                margin-right: auto;
            }
        }
    }

    .category__header-image-right {
        position: relative;

        @include breakpoint(medium) {
            height: 100%;
        }

        picture {
            display: flex;
        }

        ._content {
            padding: $grid__padding;
            position: absolute;
            display: flex;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            flex-direction: column;
            justify-content: center;
            gap: $grid__padding;
            align-items: center;

            ._title {
                font-size: clamp(2rem, 0rem + 4vw, 4rem);
                font-weight: 700;
                text-transform: uppercase;
            }

            &._white {
                ._title, p {
                    @include text-shadow();
                    color: color(Base, White);
                }
            }

            &._black {
                ._title, p {
                    color: color(Base, Black);
                }
            }
        }
    }

    .--transparent-button-with-arrow {
        margin-top: 1.6rem;
    }
}
