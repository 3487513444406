@mixin sticky-product() {
    div > .product__tile-container,
    > ul > li > .product__tile-container {
        .product__wishlist {
            display: none;
        }

        .product__tile {
            flex-direction: row;

            .product__label {
                display: none;
            }

            .product__holder {
                gap: .4rem;
            }

            ._manufacturer {
                display: none;
            }
        }
    }
}

:root {
    --compare__products--width: 26rem;
}

.catalog-product-compare {
    @include breakpoint(small only) {
        .grid-container {
            padding: 0;

            .grid-x.grid-padding-x {
                margin: 0;

                .cell {
                    padding: 0;
                }
            }
        }

        .breadcrumbs {
            display: none;
        }

        .product__compare--container {
            overflow-x: auto;
        }

        .product__compare--header {
            padding: 0 $grid__padding;
        }
    }

    .mobile__compare {
        background-color: color(Gray, Gray-300);
        padding: 1rem;
        position: relative;
        width: calc(var(--compare__products--width) * 4);

        > div {
            position: sticky;
            left: 1rem;
            right: 1rem;
            display: inline-flex;
            gap: .8rem;
            align-items: center;

            label {
                font-size: 1.3rem;
                font-weight: 600;
            }
        }
    }

    .product__compare--container {
        position: relative;

        &.--products-3 {
            .mobile__compare, .mobile__title {
                width: calc(var(--compare__products--width) * 3);
            }
        }

        &.--products-2 {
            .mobile__compare, .mobile__title {
                width: calc(var(--compare__products--width) * 2);
            }
        }

        &.--products-1 {
            .mobile__compare, .mobile__title {
                width: calc(var(--compare__products--width) * 1);
            }
        }

        .mobile__title {
            transition: max-height .3s linear, background 1.5s ease-in-out;
            border-bottom: .1rem solid color(Gray, Gray-300);
            background-color: color(Gray, Gray-200);
            width: calc(var(--compare__products--width) * 4);
            position: relative;
            max-height: 3.2rem;

            &.--hidden {
                background-color: transparent;
                max-height: 0;
                border-bottom: none;
                overflow: hidden;
            }

            > div {
                padding: .8rem;
                position: sticky;
                display: inline-flex;
                gap: .4rem;
                align-items: center;
                left: 0;
                right: 0;

                strong {
                    font-size: 1.3rem;
                    font-weight: 600;
                }
            }
        }


        .--products, .--attributes {
            display: flex;

            > div {
                flex: 1;
                padding: .8rem;
                font-size: 1.4rem;
                line-height: 2rem;
                border-right: .1rem solid color(Gray, Gray-200);

                strong {
                    font-weight: 600;
                }

                @include breakpoint(medium) {
                    font-size: 1.6rem;
                    line-height: 2.4rem;
                    padding: $grid__padding;

                    &:not(:last-child) {
                        border-right: .1rem solid color(Gray, Gray-400);
                    }
                }
            }

            @include breakpoint(small only) {
                > div {
                    flex: 0 0 var(--compare__products--width);

                    &:first-child {
                        display: none;
                    }
                }
            }

            &:is(.--attributes) {
                transition: max-height .6s linear, background .3s ease-in-out;
                max-height: 40rem;

                &.--hidden {
                    max-height: 0;
                    overflow: hidden;
                }

                @include breakpoint(medium) {
                    overflow: hidden;

                    &.--even {
                        background-color: color(Gray, Gray-200);
                    }
                }

                @include hover() {
                    background-color: #e8f3ff;
                }
            }

            &:is(.--products) {
                transition: all .3s ease-in-out;
                box-shadow: rgba(33, 35, 38, 0.1) 0 1rem 1rem -1rem;
                background: rgba(255, 255, 255, .75);
                backdrop-filter: blur(1rem);

                @include breakpoint(small only) {
                    top: 0;

                    &.catalog__category-list {
                        margin: 0;
                    }

                    &.--sticky {
                        transition: none;
                    }
                }

                .--product {
                    position: relative;

                    .--form {
                        position: absolute;
                        top: .6rem;
                        right: .8rem;
                        z-index: 4;

                        form {
                            display: flex;

                            .--remove {
                                @include transition();
                                right: 0;
                                width: 2.4rem;
                                height: 2.4rem;
                                cursor: pointer;
                                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='2' stroke='#{color(Gray, Gray-600)}' class='size-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M6 18 18 6M6 6l12 12' /%3E%3C/svg%3E%0A");
                                background-repeat: no-repeat;
                                background-position: center;

                                @include hover() {
                                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='2' stroke='#{color(Gray, Gray-800)}' class='size-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M6 18 18 6M6 6l12 12' /%3E%3C/svg%3E%0A");
                                }
                            }
                        }
                    }

                    .--tile {
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                    }
                }

                @include breakpoint(small only) {
                    @include sticky-product();
                }

                > div {
                    padding: 0 .1rem;
                    display: flex;
                    flex-direction: column;

                    &.compare__information {
                        justify-content: flex-end;
                        align-items: flex-end;

                        @include breakpoint(small only) {
                            display: none;
                        }

                        > div {
                            padding: $grid__padding;
                            display: flex;
                            align-items: center;
                            gap: .8rem;

                            label {
                                font-size: 1.4rem;
                                font-weight: 600;
                            }
                        }
                    }
                }

                @include breakpoint(medium) {
                    &.--sticky {
                        position: fixed;
                        z-index: 3;
                        margin-top: 0;
                        top: -100%;
                        max-width: $grid__max-width - $grid__padding * 2;
                        width: 100%;

                        .--product {
                            @include sticky-product();

                            .product__holder {
                                padding-right: 1rem;
                            }
                        }
                    }
                }
            }
        }

        .catalog__category-list div,
        .catalog__category-list > ul > li {
            .product__tile-container {
                height: auto;
                background-color: transparent;
                border: none;
                flex: 1;

                .product__wishlist {
                    display: block;
                    right: .8rem;
                    top: .8rem;

                    &.--right {
                        right: 3.4rem;
                    }
                }

                .product__tile {
                    padding: $grid__padding $grid__padding 0;

                    .product__stickers {
                        display: none;
                    }

                    @include breakpoint(small only) {
                        .product__holder {
                            padding-right: 1rem;
                        }
                    }
                }
            }

            .add-to-cart__holder {
                border: none;
                margin-left: 0;
            }
        }
    }
}

.compare__products--container {
    @include transition();
    border-radius: .8rem .8rem 0 0;
    filter: drop-shadow(.1rem .1rem .2rem rgba(95, 95, 95, .2));
    overflow: clip;
    position: fixed;
    bottom: 0;
    z-index: 11;
    right: $grid__padding;
    left: $grid__padding;
    width: auto;

    @include breakpoint(medium) {
        right: 5%;
        left: auto;
        width: 25rem;
    }

    .compare__products--header {
        transition: background .3s ease-in-out;
        background-color: color(Base, PrimaryColor);
        font-size: 1.3rem;
        color: color(Base, White);
        font-weight: 700;
        line-height: 3.6rem;
        padding: 0 $grid__padding;
        background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='mask0_26_6' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='24' height='24'%3E%3Cpath d='M0 0L24 2.09815e-06L24 24L-2.09815e-06 24L0 0Z' fill='%23D9D9D9'/%3E%3C/mask%3E%3Cg mask='url(%23mask0_26_6)'%3E%3Cpath d='M7.39999 15.038L6.34599 13.985L12 8.32999L17.654 13.984L16.6 15.038L12 10.438L7.39999 15.038Z' fill='#{color(Base, White)}'/%3E%3C/g%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        background-position: right $grid__padding center;
        width: 100%;

        @include breakpoint(medium) {
            font-size: 1.5rem;
            line-height: 4.2rem;
        }
    }

    .compare__products--content {
        background-color: color(Gray, Gray-200);
        padding: .8rem;

        .compare__products--list {
            @include border-radius();
            background-color: color(Base, White);
            overflow: hidden;

            .--product {
                display: flex;
                align-items: flex-start;
                gap: .8rem;
                padding: .8rem;

                .--info {
                    flex: 1;

                    .--name {
                        font-size: 1.6rem;
                        font-weight: 600;
                    }

                    .price__holder {
                        @include price-component();

                        .price__final-price, .price__special-price {
                            font-size: 1.8rem;
                            line-height: 2.8rem;

                            .--decimal {
                                font-size: 1.2rem;
                                line-height: 2.4rem;
                            }
                        }

                        .price__old-price {
                            font-size: 1.4rem;
                            line-height: 2.8rem;

                            .--decimal {
                                font-size: 1rem;
                                line-height: 2.8rem;
                            }
                        }
                    }
                }

                &:not(:last-child) {
                    border-bottom: .1rem solid color(Gray, Gray-300);
                }

                > button {
                    @include transition();
                    flex: 2.2rem 0 0;
                    height: 2.2rem;
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='2.5' stroke='#{color(Gray, Gray-500)}' class='size-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M6 18 18 6M6 6l12 12' /%3E%3C/svg%3E%0A");
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: 1.8rem;

                    @include hover() {
                        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='2.5' stroke='#{color(Gray, Gray-700)}' class='size-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M6 18 18 6M6 6l12 12' /%3E%3C/svg%3E%0A");
                    }
                }
            }
        }

        .compare__products--footer {
            margin-top: .8rem;

            a {
                width: 100%;
                text-align: center;

                &.--disabled {
                    @include button-background(color(Gray, Gray-600));
                    pointer-events: none;
                }
            }
        }
    }

    &.--active {
        @include breakpoint(medium) {
            width: 35rem;
        }

        .compare__products--header {
            background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='24' height='24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='a' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='24' height='24'%3E%3Cpath transform='rotate(-180 24 24)' fill='%23D9D9D9' d='M24 24h24v24H24z'/%3E%3C/mask%3E%3Cg mask='url(%23a)'%3E%3Cpath d='m16.6 8.962 1.054 1.053L12 15.67l-5.654-5.654L7.4 8.962l4.6 4.6 4.6-4.6Z' fill='#{color(Base, White)}'/%3E%3C/g%3E%3C/svg%3E");
        }
    }
}

.compare__toggle {
    display: flex;
    justify-content: flex-end;
    gap: .6rem;
    align-items: center;

    @include breakpoint(small only) {
        align-self: flex-end;
    }

    label {
        @include transition();
        font-size: 1.2rem;
        font-weight: 600;
        color: color(Gray, Gray-700);
        cursor: pointer;
    }

    input {
        @include transition();
        @include radio-checkbox();
        border-color: color(Gray, Gray-700);

        @include breakpoint(small only) {
            width: 2.2rem;
            height: 2.2rem;
            flex: 0 0 2.2rem;
        }
    }

    &.--disabled {
        label {
            color: color(Gray, Gray-500);
        }

        input {
            border-color: color(Gray, Gray-500);
        }
    }
}
