.mini__cart {
    background: color(Gray, Gray-200);
    position: absolute;
    width: 44rem;
    font-size: 1.6rem;
    padding: .8rem;
    z-index: 4;
    top: 100%;
    right: 0;

    .mini__cart__header {
        margin: 0 0 .8rem;

        h6 {
            font-weight: 600;
            font-size: 1.4rem;
        }
    }

    > .--loading {
        @include border-radius(.5rem);
        gap: .8rem;
        font-size: 1.4rem;
        padding: 2.4rem;
        background-color: color(Base, White);
        margin: 0 0 .8rem;

        .--spinner {
            width: 2.3rem;
            height: 2rem;
        }
    }

    .mini__cart__content {
        @include border-radius(.5rem);
        @include styled-scrollbar();
        background-color: color(Base, White);
        max-height: 30rem;
        overflow-y: auto;

        > div {
            padding: .8rem;
            position: relative;

            .--loading-overlay {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background: rgba(255, 255, 255, .4);
                display: flex;
                justify-content: center;
                align-items: center;
            }

            &:not(:last-child) {
                border-bottom: .1rem solid color(Gray, Gray-200);
            }

            .mini__cart--item {
                display: flex;
                gap: .8rem;

                .--information {
                    flex: 1;

                    ul {
                        font-size: 1.3rem;
                        line-height: 2rem;

                        li {
                            color: color(Gray, Gray-600);

                            > span {
                                color: color(Gray, Gray-900);

                                &.--semi-bold {
                                    display: block;
                                    font-weight: 600;
                                }

                                &.bundle__options {
                                    > span {
                                        display: block;
                                    }
                                }
                            }
                        }
                    }
                }

                .price__holder {
                    @include price-component();
                    .price__old-wrapper{
                        display:flex;
                        gap:.4rem;
                        .price__old-price .--decimal{
                            font-size: 1.1rem;
                            line-height: 2.3rem;
                        }
                    }
                    .price__old-label{
                        font-weight: 500;
                        font-size:1.2rem;
                    }

                }
            }

            .--actions {
                margin: .8rem 0 0;
                display: flex;
                justify-content: flex-end;

                > button {
                    font-size: 1.4rem;
                    color: color(Gray, Gray-600);
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='#{color(Gray, Gray-600)}' class='size-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0' /%3E%3C/svg%3E%0A");
                    background-repeat: no-repeat;
                    background-position: center right;
                    background-size: 1.6rem;
                    padding-right: 2.2rem;

                    @include hover() {
                        text-decoration: underline;
                    }
                }
            }
        }
    }

    .mini__cart__footer {
        margin-top: .8rem;
        display: flex;
        flex-direction: column;

        ul {
            li {
                color: color(Gray, Gray-700);
                display: flex;
                justify-content: space-between;
                gap: 4.2rem;
                font-size: 1.6rem;
                padding: .8rem;

                &._small {
                    font-size: 1.4rem;
                }

                &._grand {
                    color: color(Base, Black);
                    font-size: 1.8rem;
                }
            }
        }

        > a {
            margin-top: .8rem;
            width: 100%;
            text-align: center;
        }
    }
}
