.rating__block {
    display: block;
    width: 11.8rem;
    height: 2.2rem;
    background-image: url("data:image/svg+xml,%3Csvg width='118' height='22' viewBox='0 0 118 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11 16.5L4.53404 19.9L5.76904 12.7L0.540039 7.6L7.76804 6.55L11 0L14.233 6.55L21.462 7.6L16.231 12.7L17.466 19.9L11 16.5ZM35 16.5L28.534 19.9L29.769 12.7L24.539 7.6L31.767 6.55L35 0L38.233 6.55L45.462 7.6L40.231 12.7L41.466 19.9L35 16.5ZM59 16.5L52.534 19.9L53.769 12.7L48.539 7.6L55.767 6.55L59 0L62.233 6.55L69.462 7.6L64.231 12.7L65.466 19.9L59 16.5ZM83 16.5L76.534 19.9L77.769 12.7L72.539 7.6L79.767 6.55L83 0L86.233 6.55L93.462 7.6L88.231 12.7L89.466 19.9L83 16.5ZM107 16.5L100.534 19.9L101.769 12.7L96.539 7.6L103.767 6.55L107 0L110.233 6.55L117.462 7.6L112.231 12.7L113.466 19.9L107 16.5Z' fill='%23E3E3E3'/%3E%3C/svg%3E%0A");

    .rating {
        display: block;
        height: 100%;
        background-image: url("data:image/svg+xml,%3Csvg width='118' height='22' viewBox='0 0 118 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11 16.5L4.534 19.9L5.769 12.7L0.540001 7.6L7.768 6.55L11 0L14.233 6.55L21.462 7.6L16.231 12.7L17.466 19.9L11 16.5ZM35 16.5L28.534 19.9L29.769 12.7L24.539 7.6L31.767 6.55L35 0L38.233 6.55L45.462 7.6L40.231 12.7L41.466 19.9L35 16.5ZM59 16.5L52.534 19.9L53.769 12.7L48.539 7.6L55.767 6.55L59 0L62.233 6.55L69.462 7.6L64.231 12.7L65.466 19.9L59 16.5ZM83 16.5L76.534 19.9L77.769 12.7L72.539 7.6L79.767 6.55L83 0L86.233 6.55L93.462 7.6L88.231 12.7L89.466 19.9L83 16.5ZM107 16.5L100.534 19.9L101.769 12.7L96.539 7.6L103.767 6.55L107 0L110.233 6.55L117.462 7.6L112.231 12.7L113.466 19.9L107 16.5Z' fill='%23D4AF37'/%3E%3C/svg%3E%0A");
    }
}
