.contact-form {
    h1 {
        margin: 0 0 20px;
        @include breakpoint(small only) {
            margin: 20px 0;
        }

        & + p {
            margin: 0 0 20px;
        }
    }

    .messages {
        margin: 0 0 10px;
    }

    ._content {
        @include breakpoint(small only) {
            margin: $grid__padding 0;
        }

        p {
            margin: 0 0 $grid__padding;
        }

        a {
            text-decoration: underline;
        }

        strong {
            display: block;
            margin: 0 0 .4rem;
        }

        li {
            line-height: 2.8rem;
        }
    }

    .global__content {
        margin: 0 0 2rem;
    }

    .validator-err {
        margin: .4rem 0 0;
        font-size: 1.2rem;
        color: color(Alert, Error);
        line-height: normal;
    }
}
