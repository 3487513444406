html[lang="de-DE"] {
    .cc_button_settings {
        background-color: color(Base, White) !important;
        color: color(Base, Black) !important;
    }
}

.cc_root {
    position: fixed;
    z-index: 100;
    margin: auto;
    left: 0;
    bottom: 0;
    right: 0;
    height: 100%;

    .cc_buttons {
        display: flex;
        justify-content: center;
        margin-top: 2rem;
        padding-bottom: 2rem;

        .cc_button_allowall,
        .cc_button_settings {
            @media screen and (max-width: 768px) {
                max-height: 40px;
                height: 40px;
            }
        }

        @media screen and (max-width: 600px) {
            flex-direction: column;
            text-align: center;

            .cc_button_rejectall,
            .cc_button_allowall,
            .cc_button_settings {
                width: 100%;
            }
        }
    }

    .cc_button_settings {
        background-color: color(Base, Black);
        color: color(Base, White);
        border: solid 1px;
        width: 150px;
    }

    .cc_text {
        display: flex;
        flex-direction: column-reverse;
        padding-left: unset;
        margin-right: unset;

        > span {
            color: white;
            order: 0;
            text-align: center;
            align-self: center;
            padding: 0 3rem;
        }

        > a {
            text-align: center;
        }

        > svg {
            background-image: url('data:image/svg+xml,<%3Fxml version="1.0" encoding="UTF-8"%3F><svg id="Laag_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 243.96 216"><defs><style>.cls-1{fill:%23fc0f40;stroke-width:0px;}</style></defs><g id="Laag_1-2"><path class="cls-1" d="m76.36,0c13.2,22.02,29.34,42.1,48.59,59.33,16.35-18.02,30.57-37.91,41.94-59.33h-90.54Z"/><path class="cls-1" d="m175.07,94.21c8.97-26.9,14.31-54.96,15.67-83.13l-1.31-2.27c-2.78-4.81-7.66-7.84-13.09-8.52-9.42,24.77-22.35,48.39-38.03,70.12,5.58,4.29,11.31,8.44,17.36,12.25,6.32,4.14,12.82,7.93,19.4,11.55Z"/><path class="cls-1" d="m197.88,39.14c-1,20.96-3.87,41.91-8.48,62.49,16.01,7.77,32.61,14.28,49.65,19.59l2.55-4.41c3.15-5.45,3.15-12.17,0-17.63l-43.72-75.72c.09,5.22.19,10.45,0,15.67Z"/><path class="cls-1" d="m14,136.97c36.02-14.4,69.52-36.46,97.83-64.03-22.49-17.41-42.34-38.19-58.16-62.61L2.36,99.19c-3.15,5.45-3.15,12.17,0,17.63l11.64,20.15Z"/><path class="cls-1" d="m186.08,115.14c-9.47,35.77-24.45,70.06-44.89,100.86h32.97c6.3,0,12.12-3.36,15.26-8.81l44.19-76.55c-16.14-4.07-32.06-9.24-47.54-15.5Z"/><path class="cls-1" d="m100.58,216c30.17-30.22,53.81-67.46,69.39-107.87-14.7-6.84-28.86-14.75-42.27-23.75-26.75,33.05-59.3,61.42-96.7,82.02l23.55,40.78c3.15,5.45,8.97,8.81,15.26,8.81h30.77Z"/></g></svg>');
            background-size: contain;
            background-repeat: no-repeat;
            position: unset !important;
            margin: 0 auto 1rem;
            width: 54px;
            height: 48px;

            > path {
                display: none;
            }

            @media screen and (max-width: 768px) {
                display: block;
                width: 15%;
            }
        }

        .cc_link_cookie_declaration {
            color: color(Base, PrimaryColor);
            order: -1;
        }
    }

    > div:first-of-type {
        @media screen and (max-width: 600px) {
            width: 95%;
        }
    }
}
