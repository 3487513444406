.content__blocks {
    @media screen and (max-width: 768px) {
        .grid-container .grid-x.grid-padding-x {
            justify-content: center;
        }
    }

    &.discover__block {
        img {
            width: 100%;
        }

        @media screen and (max-width: 768px) and (min-width: 47.99875em) {
            .between-medium-small-8:last-of-type {
                margin: 1.250rem 0 0;
            }
        }
    }

    .content__holder {
        @include border-radius();
        border: .1rem solid color(Gray, Gray-300);
        overflow: hidden;
        margin: 0 0 $grid__padding 0;
        display: flex;
        flex-direction: column;

        @include breakpoint(medium) {
            height: 100%;
        }

        picture {
            display: flex;
        }

        ._content {
            padding: $grid__padding $grid__gutter;
            display: flex;
            flex-direction: column;
            align-items: center;
            flex: 1;

            h2 {
                font-size: 2rem;
                line-height: 3.6rem;
                margin: 0 0 .4rem;
            }

            p {
                flex: 1;
                text-align: center;
            }

            .--buttons {
                display: flex;
                align-items: center;
                gap: $grid__padding;

                @include breakpoint(small only) {
                    flex-direction: column;
                    gap: .8rem;
                }
            }

            .--button {
                margin: $grid__padding 0 0;
            }
        }
    }
}
