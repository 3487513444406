.page-builder__image-banner__block {
    .image__banner-container {
        display: flex;
        gap: 3rem;

        @include breakpoint(small only) {
            flex-direction: column;
        }

        .image__banner {
            display: block;
            flex: 1;

            > span {
                position: relative;
                @include border-radius();
                display: flex;
                justify-content: space-between;
                align-items: flex-end;
                background-size: contain;
                background-repeat: no-repeat;
                background-position: right center;
                height: 100%;

                @include breakpoint(small only) {
                    flex-direction: column;
                    background-size: cover;
                }

                &.--black {
                    background-color: color(Base, Black);
                    background-image: url("data:image/svg+xml,%3Csvg width='450' height='286' viewBox='0 0 450 286' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath opacity='0.15' fill-rule='evenodd' clip-rule='evenodd' d='M0.175781 285.15C80.7213 257.755 161.356 216.068 220.312 153.46C161.406 107.426 113.322 45.6608 74.6836 -17.6937L115.434 -40.3054V-40.2898C146.063 23.2527 185.519 85.8634 236.766 134.731C252.347 115.683 265.989 94.8773 277.185 72.1592C294.982 35.8742 305.154 -4.58313 307.038 -45.853C309.029 -4.61438 302.716 37.5306 287.82 76.8628C278.193 102.162 265.676 125.694 250.862 147.557C264.023 158.97 277.918 169.477 292.589 178.888C295.995 167.903 298.841 156.681 301.063 145.214C308.172 110.505 309.654 75.0995 311.138 39.6374C312.219 13.8077 313.302 -12.0523 316.561 -37.6958C315.71 -11.6833 317.088 14.1662 318.472 40.1306C319.273 55.1727 320.077 70.2532 320.451 85.4263C321.381 120.352 319.066 155.781 311.398 190.1C321.827 195.856 332.625 201.068 343.805 205.673C379.199 220.268 417.974 227.066 456.918 225.566C418.359 230.973 378.149 228.191 340.054 216.799C328.981 213.491 318.179 209.617 307.644 205.226C307.207 206.821 306.758 208.413 306.296 210.002C282.942 291.776 232.93 362.252 178.566 425.805L145.055 393.224C203.446 337.203 258.551 272.404 286.865 195.74C269.635 187.179 253.158 177.236 237.413 166.155C181.373 238.932 99.5726 291.827 17.0161 328.998L0.175781 285.15Z' fill='white'/%3E%3C/svg%3E%0A");

                    .--content .--sub-title {
                        color: color(Base, SecondaryColor);
                    }
                }

                &.--red {
                    background-color: color(Base, PrimaryColor);
                    background-image: url("data:image/svg+xml,%3Csvg width='493' height='268' viewBox='0 0 493 268' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath opacity='0.08' fill-rule='evenodd' clip-rule='evenodd' d='M0.308594 277.194C93.9647 245.479 187.724 197.219 256.277 124.739C187.783 71.4464 131.873 -0.0570297 86.9453 -73.4006L134.328 -99.5776V-99.5595C169.943 -25.9984 215.821 46.4841 275.409 103.057C293.525 81.0058 309.387 56.9196 322.406 30.6197C343.1 -11.3865 354.928 -58.2229 357.118 -106C359.433 -58.2591 352.092 -9.46889 334.772 36.065C323.578 65.353 309.024 92.595 291.799 117.905C307.103 131.117 323.258 143.282 340.318 154.176C344.278 141.46 347.587 128.469 350.171 115.193C358.437 75.0111 360.161 34.0237 361.886 -7.02983C363.143 -36.9323 364.402 -66.8697 368.191 -96.5566C367.203 -66.4427 368.804 -36.5174 370.413 -6.45915C371.345 10.9547 372.28 28.4131 372.714 45.9787C373.796 86.4108 371.105 127.426 362.188 167.157C374.315 173.821 386.871 179.853 399.871 185.185C441.026 202.082 486.112 209.951 531.395 208.215C486.561 214.474 439.806 211.254 395.51 198.066C382.634 194.236 370.073 189.75 357.823 184.667C357.315 186.514 356.793 188.357 356.256 190.196C329.1 284.864 270.948 366.453 207.735 440.027L168.77 402.308C236.665 337.454 300.74 262.437 333.662 173.686C313.627 163.775 294.469 152.264 276.161 139.435C211 223.688 115.884 284.924 19.89 327.956L0.308594 277.194Z' fill='black'/%3E%3C/svg%3E%0A");
                }

                &.--gold {
                    background-color: color(Base, SecondaryColor);
                    background-image: url("data:image/svg+xml,%3Csvg width='493' height='268' viewBox='0 0 493 268' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath opacity='0.08' fill-rule='evenodd' clip-rule='evenodd' d='M0.308594 277.194C93.9647 245.479 187.724 197.219 256.277 124.739C187.783 71.4464 131.873 -0.0570297 86.9453 -73.4006L134.328 -99.5776V-99.5595C169.943 -25.9984 215.821 46.4841 275.409 103.057C293.525 81.0058 309.387 56.9196 322.406 30.6197C343.1 -11.3865 354.928 -58.2229 357.118 -106C359.433 -58.2591 352.092 -9.46889 334.772 36.065C323.578 65.353 309.024 92.595 291.799 117.905C307.103 131.117 323.258 143.282 340.318 154.176C344.278 141.46 347.587 128.469 350.171 115.193C358.437 75.0111 360.161 34.0237 361.886 -7.02983C363.143 -36.9323 364.402 -66.8697 368.191 -96.5566C367.203 -66.4427 368.804 -36.5174 370.413 -6.45915C371.345 10.9547 372.28 28.4131 372.714 45.9787C373.796 86.4108 371.105 127.426 362.188 167.157C374.315 173.821 386.871 179.853 399.871 185.185C441.026 202.082 486.112 209.951 531.395 208.215C486.561 214.474 439.806 211.254 395.51 198.066C382.634 194.236 370.073 189.75 357.823 184.667C357.315 186.514 356.793 188.357 356.256 190.196C329.1 284.864 270.948 366.453 207.735 440.027L168.77 402.308C236.665 337.454 300.74 262.437 333.662 173.686C313.627 163.775 294.469 152.264 276.161 139.435C211 223.688 115.884 284.924 19.89 327.956L0.308594 277.194Z' fill='black'/%3E%3C/svg%3E%0A");
                }

                .image__holder {
                    display: flex;
                    margin-top: -2.4rem;
                    height: calc(100% + 2.4rem);
                    align-items: flex-end;
                    justify-content: center;
                    margin-right: .8rem;

                    @include breakpoint(medium) {
                        order: 1;
                        align-items: center;
                        margin-right: 4.2rem;
                    }

                    picture {
                        display: flex;
                    }
                }

                .--content {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    padding: $grid__padding;
                    max-width: 68%;

                    @include breakpoint(medium) {
                        position: initial;
                        order: 0;
                        padding: clamp(1.6rem, 0.412rem + 2.375vw, 3.5rem) clamp(2.4rem, 1.275rem + 2.25vw, 4.2rem);
                        max-width: 55%;
                    }

                    .--sub-title {
                        @include text-shadow();
                        font-size: clamp(1.6rem, 1.475rem + 0.25vw, 1.8rem);
                        line-height: clamp(1.8rem, 1.675rem + 0.25vw, 2rem);
                        font-weight: 700;
                        color: color(Base, White);
                        display: block;
                        margin-bottom: .8rem;
                    }

                    .--title {
                        @include text-shadow();
                        font-size: clamp(2.2rem, 1.575rem + 1.25vw, 3.2rem);
                        line-height: clamp(2.4rem, 1.65rem + 1.5vw, 3.6rem);
                        font-weight: 700;
                        color: color(Base, White);
                        display: block;
                    }
                }

                .--button {
                    position: absolute;
                    right: 2.4rem;
                    bottom: $grid__padding;
                    z-index: 2;

                    @include breakpoint(medium) {
                        right: 3.2rem;
                        bottom: 2.4rem;
                    }
                }
            }
        }
    }
}
