.off-canvas.position-left:not(.in-canvas-for-medium) {
    visibility: hidden;

    &.is-open {
        visibility: visible;
        transform: translateX(0);
    }
}

.js-off-canvas-overlay {
    background: rgba(0, 0, 0, .7);
    z-index: 12;
}

.off-canvas {
    display: flex;
    flex-direction: column;
    height: 100%;
    z-index: 13;

    @include breakpoint(small only) {
        &.position-left {
            width: 85%;
            transform: translateX(-100%);
            overflow-x: hidden;
        }
    }

    &.is-open {
        transform: translateX(0);
    }

    .modal__header {
        flex: 0 0 auto;
        height: 5.6rem;
        padding: $grid__padding 1.8rem $grid__padding 4.8rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-image: url("data:image/svg+xml,%3Csvg width='21' height='19' viewBox='0 0 21 19' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.34424 0.239502C7.44386 2.08365 8.78823 3.76674 10.3936 5.21093C11.7559 3.70159 12.9401 2.03479 13.8886 0.239502H6.34424Z' fill='%23FC0F40'/%3E%3Cpath d='M14.6147 8.12522C15.3766 5.86775 15.832 3.51237 15.9458 1.14611L15.8338 0.955726C15.597 0.551377 15.182 0.297525 14.7211 0.239502C13.9206 2.31927 12.8208 4.30113 11.4878 6.12523C11.9615 6.48606 12.4499 6.8342 12.964 7.15333C13.502 7.50147 14.0546 7.81878 14.6128 8.1234L14.6147 8.12522Z' fill='%23FC0F40'/%3E%3Cpath d='M16.4664 3.58755C16.3852 5.31472 16.1504 7.04189 15.7744 8.73878C17.0824 9.37979 18.4381 9.91575 19.8309 10.3538L20.0391 9.99053C20.2969 9.54183 20.2969 8.98628 20.0391 8.53758L16.4664 2.29663C16.4734 2.72753 16.4823 3.15843 16.4664 3.58933V3.58755Z' fill='%23FC0F40'/%3E%3Cpath d='M1.15972 11.7253C4.14083 10.5161 6.91449 8.66521 9.25897 6.35205C7.39734 4.89091 5.75388 3.14698 4.44305 1.09668L0.19582 8.55463C-0.0652733 9.01146 -0.0652733 9.57706 0.19582 10.0339L1.15972 11.7253Z' fill='%23FC0F40'/%3E%3Cpath d='M15.4948 9.8414C14.7226 12.8201 13.499 15.6764 11.8311 18.2396H14.5216C15.0346 18.2396 15.5107 17.959 15.7681 17.5057L19.3754 11.1311C18.0584 10.7929 16.7589 10.3612 15.4948 9.8396V9.8414Z' fill='%23FC0F40'/%3E%3Cpath d='M8.32291 18.2395C10.8173 15.7204 12.7706 12.616 14.0598 9.24699C12.8438 8.67618 11.6743 8.01714 10.5655 7.26807C8.35505 10.0231 5.66428 12.3891 2.57178 14.1052L4.51798 17.5048C4.77867 17.9586 5.25897 18.2395 5.78034 18.2395H8.32469H8.32291Z' fill='%23FC0F40'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        background-position: left $grid__padding center;

        .--title {
            display: block;
            font-weight: 600;
            line-height: 2.4rem;
            font-size: 1.4rem;
            text-transform: uppercase;
        }

        .close-button {
            position: initial;

            span {
                width: 2.4rem;
                height: 2.4rem;
                display: block;
                background-repeat: no-repeat;
                background-image: url("data:image/svg+xml,%3Csvg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='mask0_596_27734' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='24' height='25'%3E%3Crect y='0.239502' width='24' height='24' fill='%23D9D9D9'/%3E%3C/mask%3E%3Cg mask='url(%23mask0_596_27734)'%3E%3Cpath d='M11.9998 13.6396L7.0998 18.5396C6.91647 18.7229 6.68314 18.8146 6.3998 18.8146C6.11647 18.8146 5.88314 18.7229 5.6998 18.5396C5.51647 18.3562 5.4248 18.1229 5.4248 17.8396C5.4248 17.5562 5.51647 17.3229 5.6998 17.1396L10.5998 12.2396L5.6998 7.33955C5.51647 7.15622 5.4248 6.92288 5.4248 6.63955C5.4248 6.35622 5.51647 6.12288 5.6998 5.93955C5.88314 5.75622 6.11647 5.66455 6.3998 5.66455C6.68314 5.66455 6.91647 5.75622 7.0998 5.93955L11.9998 10.8396L16.8998 5.93955C17.0831 5.75622 17.3165 5.66455 17.5998 5.66455C17.8831 5.66455 18.1165 5.75622 18.2998 5.93955C18.4831 6.12288 18.5748 6.35622 18.5748 6.63955C18.5748 6.92288 18.4831 7.15622 18.2998 7.33955L13.3998 12.2396L18.2998 17.1396C18.4831 17.3229 18.5748 17.5562 18.5748 17.8396C18.5748 18.1229 18.4831 18.3562 18.2998 18.5396C18.1165 18.7229 17.8831 18.8146 17.5998 18.8146C17.3165 18.8146 17.0831 18.7229 16.8998 18.5396L11.9998 13.6396Z' fill='black'/%3E%3C/g%3E%3C/svg%3E%0A");
                background-position: center;
            }
        }
    }

    .modal__content {
        flex: 1 1 auto;
        overflow-x: hidden;
        overflow-y: visible;

        .menu {
            &.drilldown {
                .js-drilldown-back button {
                    width: 100%;
                    line-height: 2.4rem;
                    padding: $grid__padding;
                    display: block;
                    position: relative;
                    font-weight: 700;
                    font-size: 1.4rem;
                    border-bottom: .1rem solid color(Gray, Gray-150);
                    background-repeat: no-repeat;
                    background-position: center right $grid__padding;
                    background-image: url("data:image/svg+xml,%0A%3Csvg width='19.199808px' height='19.6363636px' viewBox='0 0 19.199808 19.6363636' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Crect id='path-dka2dt64ms-1' x='0' y='0' width='19.199808' height='19.6363636'%3E%3C/rect%3E%3C/defs%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Group-2' transform='translate(-10.4001, -8.1818)'%3E%3Cg id='Clipped' transform='translate(20, 18) scale(-1, 1) translate(-20, -18)translate(10.4001, 8.1818)'%3E%3Cmask id='mask-dka2dt64ms-2' fill='white'%3E%3Cuse xlink:href='%23path-dka2dt64ms-1'%3E%3C/use%3E%3C/mask%3E%3Cg id='Rectangle'%3E%3C/g%3E%3Cpolygon id='Path' fill='%23000000' fill-rule='nonzero' mask='url(%23mask-dka2dt64ms-2)' points='11.0766892 14.4440182 10.2334977 13.5566182 13.2894671 10.4318182 3.599964 10.4318182 3.599964 9.20454545 13.2894671 9.20454545 10.2334977 6.07974545 11.0766892 5.19234545 15.599844 9.81818182'%3E%3C/polygon%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
                }

                li {
                    &.is-drilldown-submenu-parent > a {
                        background-repeat: no-repeat;
                        background-position: center right $grid__padding;
                        background-image: url("data:image/svg+xml,%3Csvg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='mask0_596_27742' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='24' height='25'%3E%3Crect y='0.479248' width='24' height='24' fill='%23D9D9D9'/%3E%3C/mask%3E%3Cg mask='url(%23mask0_596_27742)'%3E%3Cpath d='M12.9462 12.4793L8.34619 7.87926L9.40002 6.82544L15.0538 12.4793L9.40002 18.1331L8.34619 17.0793L12.9462 12.4793Z' fill='black'/%3E%3C/g%3E%3C/svg%3E%0A");
                    }

                    a, .title {
                        @include off-canvas-item();
                    }

                    &.mobile__menu--top {
                        align-items: center;
                        display: flex;
                        flex-wrap: wrap;
                        gap: $grid__padding;
                        padding: $grid__padding;

                        ul {
                            display: flex;
                            flex: 1;
                            flex-wrap: wrap;
                            gap: 1rem;
                            visibility: visible;

                            li {
                                &:first-child {
                                    display: none;
                                }

                                a {
                                    font-size: 1.3rem;
                                    font-weight: 400;
                                    line-height: 2.4rem;
                                    padding: 0;
                                    border: none;
                                }
                            }
                        }

                        img {
                            flex: 0 0 9.5rem;
                            height: auto;
                            max-height: 3.2rem;
                            max-width: 9.5rem;
                        }
                    }

                    &.red {
                        background-color: color(Base, PrimaryColor);

                        &.is-drilldown-submenu-parent > a {
                            background-image: url("data:image/svg+xml,%3Csvg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='mask0_596_27742' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='24' height='25'%3E%3Crect y='0.479248' width='24' height='24' fill='%23D9D9D9'/%3E%3C/mask%3E%3Cg mask='url(%23mask0_596_27742)'%3E%3Cpath d='M12.9462 12.4793L8.34619 7.87926L9.40002 6.82544L15.0538 12.4793L9.40002 18.1331L8.34619 17.0793L12.9462 12.4793Z' fill='#{color(Base, White)}'/%3E%3C/g%3E%3C/svg%3E%0A");
                        }

                        > a, > span {
                            color: color(Base, White);
                        }
                    }

                    &.black {
                        background-color: color(Base, Black);

                        &.is-drilldown-submenu-parent > a {
                            background-image: url("data:image/svg+xml,%3Csvg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='mask0_596_27742' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='24' height='25'%3E%3Crect y='0.479248' width='24' height='24' fill='%23D9D9D9'/%3E%3C/mask%3E%3Cg mask='url(%23mask0_596_27742)'%3E%3Cpath d='M12.9462 12.4793L8.34619 7.87926L9.40002 6.82544L15.0538 12.4793L9.40002 18.1331L8.34619 17.0793L12.9462 12.4793Z' fill='#{color(Base, White)}'/%3E%3C/g%3E%3C/svg%3E%0A");
                        }

                        > a, > span {
                            color: color(Base, White);
                        }
                    }

                    &.gold {
                        background-color: color(Base, SecondaryColor);
                    }
                }

                .header__language {
                    > span {
                        display: none;
                    }

                    li a {
                        gap: .8rem;
                    }
                }
            }
        }
    }

    .modal__footer {
        flex: 0 0 auto;
    }
}

.off-canvas-module {
    &.position-right {
        right: 0;
        left: auto;
    }

    &.is-open {
        height: 100%;
        min-height: 100%;
        display: flex;
        flex-direction: column;

        .modal__header {
            display: flex;
            line-height: 6rem;
            text-align: center;
            font-weight: 600;
        }

        @include breakpoint(medium up) {
            width: 32rem;
        }
        @include breakpoint(large up) {
            width: 42rem;
        }
    }

    &:not(.is-open) {
        transform: translateX(25rem);

        @include breakpoint(medium up) {
            transform: translateX(32rem);
        }
        @include breakpoint(large up) {
            transform: translateX(42rem);
        }
    }

    > section {
        display: flex;
        flex-direction: column;
        height: 100%;
        left: 0;
        position: fixed;
        top: 0;
        width: 100%;

        .modal__content {
            height: 100%;
            overflow-y: auto;
            font-size: 1.5rem;
            line-height: 2.1rem;

            .off-canvas__page-builder__wrapper {
                margin: $grid__padding 0;
            }

            .--table {
                ul {
                    margin: 1rem 0 0;

                    li {
                        display: flex;
                        line-height: normal;
                        padding: 1rem 1.5rem;
                        font-size: 1.5rem;
                        min-height: 2.6rem;
                        align-items: center;
                        @include breakpoint(small only) {
                            font-size: 1.3rem;
                            padding: 1rem;
                        }

                        &:nth-child(odd) {
                            background: color(Gray, Gray-100);
                        }
                    }
                }
            }

            table {
                font-size: 1.3rem;
                width: 100%;

                @include breakpoint(medium) {
                    font-size: 1.5rem;
                }

                tr {
                    &:nth-child(even) {
                        background: color(Gray, Gray-150);
                    }

                    th {
                        font-weight: 600;
                        text-align: left;
                        padding: 1rem;
                    }

                    td {
                        line-height: 4.6rem;
                        padding: 0 $grid__padding;
                    }
                }
            }

            .team-wear__popup {
                padding: 0 $grid__padding;
            }

            ._content, .--content {
                padding: 0 $grid__padding;

                span {
                    display: block;
                    font-size: 1.8rem;
                    line-height: 2.8rem;
                    font-weight: 700;
                    margin: 0 0 1rem;
                }

                ul, ol {
                    margin: 0 0 1rem 1.8rem;

                    &:is(ul) {
                        list-style: square outside;
                    }

                    &:is(ol) {
                        list-style: decimal outside;
                    }

                    li {
                        color: color(Gray, Gray-800);
                        font-size: 1.5rem;
                        line-height: 2.1rem;
                        padding: .2rem 0;
                    }
                }

                p {
                    font-size: 1.5rem;
                    line-height: 2.1rem;
                    margin: 0 0 1rem;
                    color: color(Gray, Gray-800);
                }
            }

            .product__size {
                display: flex;
                flex-flow: column;
                gap: 2rem;

                ._image {
                    text-align: center;

                    img {
                        margin: 0 auto;
                    }
                }

                table tr {
                    th {
                        font-size: 1.3rem;
                    }

                    td {
                        line-height: 2.2rem;
                        padding: .8rem $grid__padding;
                        vertical-align: middle;
                        font-size: 1.3rem;
                        white-space: nowrap;
                    }
                }
            }

            .specification__off-canvas {
                padding: 0 $grid__padding;
            }
        }
    }
}
