.catalog__category-filter {
    @include breakpoint(small only) {
        .off-canvas.position-left:not(.position-right) {
            width: 100%;
        }
    }

    dl {
        @include breakpoint(medium) {
            padding: $grid__padding 0;
            border-bottom: .1rem solid color(Gray, Gray-150);

            &:first-child {
                padding-top: 0;
            }
        }

        &.catalog__category-sub-categories {
            dd {
                ol {
                    flex: none;

                    &:not(.--standalone) {
                        @include breakpoint(medium) {
                            border-left: .2rem solid color(Gray, Gray-150);
                            margin-left: .3rem;
                        }

                        li {
                            @include breakpoint(medium) {
                                padding: 0 1rem;
                            }

                            &.--pipe {
                                a {
                                    position: relative;

                                    &::before {
                                        transition: background-color .15s ease-in-out;
                                        content: '';
                                        position: absolute;
                                        width: .2rem;
                                        top: 0;
                                        bottom: 0;
                                        left: 0;

                                        @include breakpoint(medium) {
                                            left: -1.2rem;
                                        }
                                    }

                                    &:hover, &.--active {
                                        text-shadow: .1rem 0 color(Base, Black);

                                        &::before {
                                            background-color: color(Base, Black);
                                        }
                                    }
                                }
                            }
                        }
                    }

                    &.--standalone {
                        margin: .8rem 0 0;

                        @include breakpoint(small only) {
                            border-top: .1rem solid color(Base, Black);
                            margin: 0;
                        }

                        li a {
                            transition: all 0.3s ease;

                            @include breakpoint(medium) {
                                border-bottom: .2rem solid transparent;
                            }

                            &:hover, &.--active {
                                text-shadow: .1rem 0 color(Base, Black);

                                @include breakpoint(medium) {
                                    border-color: color(Base, Black);
                                }
                            }
                        }
                    }
                }
            }
        }

        dt {
            @include breakpoint(small only) {
                @include off-canvas-item();
                background-repeat: no-repeat;
                background-position: center right $grid__padding;
                background-image: url("data:image/svg+xml,%3Csvg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='mask0_596_27742' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='24' height='25'%3E%3Crect y='0.479248' width='24' height='24' fill='%23D9D9D9'/%3E%3C/mask%3E%3Cg mask='url(%23mask0_596_27742)'%3E%3Cpath d='M12.9462 12.4793L8.34619 7.87926L9.40002 6.82544L15.0538 12.4793L9.40002 18.1331L8.34619 17.0793L12.9462 12.4793Z' fill='black'/%3E%3C/g%3E%3C/svg%3E%0A");
            }

            @include breakpoint(medium) {
                font-weight: 700;
                font-size: 1.8rem;
                line-height: 2.8rem;
            }

            .label__wrapper {
                display: flex;
                align-items: center;
                gap: .8rem;
            }

            .active-label__wrapper {
                margin-left: auto;
                font-size: 1.2rem;
                font-weight: normal;
            }
        }

        dd {
            @include breakpoint(small only) {
                display: flex;
                flex-direction: column;
                background-color: color(Base, White);
                transition: transform .15s ease-in-out;
                margin: 0;
                padding: 0;
                top: 0;
                z-index: 400;
                position: fixed;
                bottom: 7.8rem;
                width: 0;
                height: 0;
                min-height: 0;
                left: 100%;
                overflow: hidden;
            }

            @include breakpoint(medium) {
                margin: 1.2rem 0 0;
            }

            .modal__header {
                .close-button > span {
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='currentColor' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18' /%3E%3C/svg%3E%0A");
                }
            }

            ol {
                @include breakpoint(small only) {
                    overflow-y: auto;
                    flex: 1;
                }

                li {
                    label, a {
                        display: flex;
                        line-height: 2.4rem;
                        padding: $grid__padding;
                        position: relative;
                        font-size: 1.4rem;
                        border-bottom: .1rem solid color(Gray, Gray-150);
                        align-items: center;
                        gap: .4rem;
                        cursor: pointer;

                        @include breakpoint(medium) {
                            display: inline-flex;
                            padding: .2rem 0;
                            font-size: 1.6rem;
                            border: none;
                            line-height: 3rem;
                        }

                        ._checkbox {
                            @include border-radius();
                            width: 1.6rem;
                            height: 1.6rem;
                            border: .1rem solid color(Gray, Gray-900);
                            margin-right: .4rem;
                            background-repeat: no-repeat;
                            background-position: center;
                        }

                        ._count {
                            font-size: 1.2rem;
                            color: color(Gray, Gray-900);
                            line-height: 2.4rem;

                            @include breakpoint(medium) {
                                line-height: 3rem;
                            }
                        }

                        &._active {
                            font-weight: 600;

                            ._checkbox {
                                background-color: color(Base, Black);
                                border-color: color(Base, Black);
                                background-image: url("data:image/svg+xml,%3Csvg width='12' height='10' viewBox='0 0 12 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.65 2.15L4.6 9.2L0.349998 4.95L1.75 3.55L4.6 6.4L10.25 0.75L11.65 2.15Z' fill='white'/%3E%3C/svg%3E%0A");
                            }

                            ._count {
                                font-weight: 400;
                            }
                        }

                        @include hover() {
                            ._checkbox {
                                background-image: url("data:image/svg+xml,%3Csvg width='12' height='10' viewBox='0 0 12 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.65 2.15L4.6 9.2L0.349998 4.95L1.75 3.55L4.6 6.4L10.25 0.75L11.65 2.15Z' fill='#{color(Gray, Gray-400)}'/%3E%3C/svg%3E%0A");
                            }
                        }
                    }
                }

                &._swatch {
                    display: flex;
                    flex-wrap: wrap;
                    gap: .8rem;

                    li {
                        min-width: 6.15rem;
                    }

                    @include breakpoint(medium) {
                        max-width: 95%;
                    }

                    @include breakpoint(small only) {
                        gap: .8rem;
                        padding: 0 .8rem;
                        flex: initial;
                    }

                    li > a {
                        width: 100%;
                        padding: 0;
                        border-bottom: none;

                        > label {
                            width: 100%;
                        }
                    }

                    li > label, li > a > label {
                        @include clickable-label-item();

                        > * {
                            font-size: 1.4rem;
                        }
                    }
                }
            }

            .price__filter {
                @include breakpoint(small only) {
                    padding: 0 $grid__padding;
                }

                .custom__controls {
                    display: flex;
                    align-items: center;
                    gap: .8rem;

                    @include tablet-only() {
                        flex-wrap: wrap;
                    }

                    span {
                        font-size: 1.4rem;
                        line-height: 2.4rem;
                    }

                    button {
                        @include small-button();
                        flex: 0 0 3.8rem;
                    }
                }
            }

            .filter__actions {
                margin: 1.2rem 0 0;
                padding: 0 0.8rem;

                @include breakpoint(medium) {
                    padding: 0;
                }

                button {
                    font-weight: 700;
                    line-height: 3rem;
                    font-size: 1.6rem;
                    display: flex;
                    gap: .8rem;
                    align-items: center;

                    &::after {
                        @include transition();
                        content: '';
                        flex: 0 0 2.8rem;
                        height: 2.8rem;
                        border-radius: 50%;
                        display: flex;
                        border: .1rem solid color(Base, SecondaryColor);
                        background-image: url("data:image/svg+xml,%3Csvg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.33333 5.66671H0.333328V4.33337H4.33333V0.333374H5.66666V4.33337H9.66666V5.66671H5.66666V9.66671H4.33333V5.66671Z' fill='black'/%3E%3C/svg%3E%0A");
                        background-repeat: no-repeat;
                        background-position: center;
                    }

                    &.--less::after {
                        background-image: url("data:image/svg+xml,%3Csvg width='10' height='2' viewBox='0 0 10 2' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.33333 1.66671H0.333328V0.333374H4.33333L5.66666 0.333374L9.66666 0.333374V1.66671H5.66666H4.33333Z' fill='black'/%3E%3C/svg%3E%0A");
                    }

                    @include hover() {
                        text-decoration: underline;
                    }
                }
            }
        }

        &._visible {
            dd {
                @include breakpoint(small only) {
                    transform: translateX(-100%);
                    width: 100%;
                    height: auto;
                    min-height: auto;
                }
            }
        }
    }

    .modal__footer {
        display: flex;
        justify-content: center;
        padding: $grid__padding;
    }
}
