.hotspot__block {
    .hotspot__container {
        position: relative;
        display: flex;
        width: fit-content;
        margin: 0 auto;

        picture {
            display: flex;
        }

        .hotspot__item {
            position: absolute;
            z-index: 4;

            &.--active {
                z-index: 6;
            }

            .hotspot__item__content {
                > button {
                    @include transition();
                    width: 5.4rem;
                    height: 5.4rem;
                    padding: .7rem;
                    z-index: 4;
                    position: relative;

                    .--glow {
                        filter: blur(0) saturate(0) opacity(0);
                        position: absolute;
                        top: .7rem;
                        right: .7rem;
                        left: .7rem;
                        bottom: .7rem;
                        animation: 2s glow 1s infinite forwards ease-in-out;
                    }

                    @keyframes glow {
                        0% {
                            filter: blur(0) saturate(0) opacity(0);
                        }
                        50% {
                            filter: blur(2rem) saturate(5) opacity(1);
                        }
                        100% {
                            filter: blur(0) saturate(0) opacity(0);
                        }
                    }

                    .--spot {
                        @include transition();
                        mask: url("data:image/svg+xml,%3Csvg width='40' height='35' viewBox='0 0 40 35' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M38.9595 15.5712C39.6207 16.7722 39.6207 18.2282 38.9595 19.4292L31.5278 32.929C30.8246 34.2064 29.4819 35 28.0236 35L12.0191 34.9996C10.5609 34.9996 9.21828 34.2061 8.51507 32.9287L1.08335 19.4288C0.422175 18.2278 0.422175 16.7718 1.08335 15.5707L8.51501 2.071C9.21826 0.793545 10.561 8.02791e-06 12.0192 3.70684e-05L28.0237 0.000355794C29.4819 0.000384834 30.8246 0.793916 31.5278 2.07133L38.9595 15.5712Z' fill='white'/%3E%3C/svg%3E%0A");
                        mask-repeat: no-repeat;
                        display: flex;
                        width: 4rem;
                        height: 3.5rem;
                        font-size: 2.2rem;
                        justify-content: center;
                        align-items: center;

                        svg {
                            width: 2.2rem;
                            height: 2.2rem;
                        }
                    }

                    &.--small {
                        width: 4.2rem;
                        height: 4.2rem;
                        padding: .6rem;

                        .--spot {
                            mask: url("data:image/svg+xml,%3Csvg width='30' height='26' viewBox='0 0 30 26' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M28.9414 11.0569C29.613 12.2654 29.613 13.7349 28.9414 14.9434L23.9393 23.9432C23.2337 25.2127 21.8954 26 20.443 26L9.59977 25.9997C8.14739 25.9997 6.80915 25.2124 6.10358 23.943L1.10146 14.943C0.429826 13.7346 0.429827 12.265 1.10147 11.0566L6.10353 2.05682C6.80913 0.787298 8.14745 1.03205e-05 9.59988 3.89705e-05L20.4431 0.000252858C21.8955 0.000281507 23.2337 0.787562 23.9393 2.05704L28.9414 11.0569Z' fill='white'/%3E%3C/svg%3E%0A");
                            width: 3rem;
                            height: 2.6rem;
                            font-size: 1.6rem;

                            svg {
                                width: 1.6rem;
                                height: 1.6rem;
                            }
                        }
                    }

                    @include hover() {
                        .--spot {
                            transform: scale(1.2);
                        }
                    }

                    &.--active {
                        .--spot {
                            transform: scale(1.2);
                        }
                    }
                }
            }

            .hotspot__item__detail {
                @include transition();
                z-index: 5;
                position: absolute;
                left: 3rem;
                top: 2.5rem;
                width: 24rem;
                text-align: left;
                background-color: color(Base, White);
                padding: 1rem;
                border: .1rem solid color(Gray, Gray-150);
                box-shadow: rgba(0, 0, 0, .3) 0 1.9rem 3.8rem, rgba(0, 0, 0, .22) 0 1.5rem 1.2rem;

                .--close {
                    @include transition();
                    position: absolute;
                    right: -1.2rem;
                    top: -1rem;
                    width: 2.4rem;
                    height: 2rem;
                    background-image: url("data:image/svg+xml,%3Csvg width='24' height='20' viewBox='0 0 24 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M22.8644 7.99835C23.5803 9.23685 23.5803 10.7634 22.8644 12.0019L19.3963 18.0017C18.6815 19.2384 17.3615 20 15.9331 20L8.10966 19.9998C6.68135 19.9998 5.36143 19.2382 4.64665 18.0016L1.17852 12.0016C0.462631 10.7631 0.46263 9.2366 1.17852 7.9981L4.6466 1.99827C5.36141 0.761639 6.6814 1.09282e-05 8.10976 3.8022e-05L15.9332 0.000186422C17.3615 0.000213515 18.6815 0.761837 19.3962 1.99843L22.8644 7.99835Z' fill='white'/%3E%3C/svg%3E%0A");
                    filter: drop-shadow(.1rem .1rem .15rem color(Gray, Gray-700));

                    @include hover() {
                        transform: rotate(90deg);
                    }

                    &::after {
                        content: '';
                        position: absolute;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;
                        background-image: url("data:image/svg+xml,%3Csvg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='mask0_596_27734' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='24' height='25'%3E%3Crect y='0.239502' width='24' height='24' fill='%23D9D9D9'/%3E%3C/mask%3E%3Cg mask='url(%23mask0_596_27734)'%3E%3Cpath d='M11.9998 13.6396L7.0998 18.5396C6.91647 18.7229 6.68314 18.8146 6.3998 18.8146C6.11647 18.8146 5.88314 18.7229 5.6998 18.5396C5.51647 18.3562 5.4248 18.1229 5.4248 17.8396C5.4248 17.5562 5.51647 17.3229 5.6998 17.1396L10.5998 12.2396L5.6998 7.33955C5.51647 7.15622 5.4248 6.92288 5.4248 6.63955C5.4248 6.35622 5.51647 6.12288 5.6998 5.93955C5.88314 5.75622 6.11647 5.66455 6.3998 5.66455C6.68314 5.66455 6.91647 5.75622 7.0998 5.93955L11.9998 10.8396L16.8998 5.93955C17.0831 5.75622 17.3165 5.66455 17.5998 5.66455C17.8831 5.66455 18.1165 5.75622 18.2998 5.93955C18.4831 6.12288 18.5748 6.35622 18.5748 6.63955C18.5748 6.92288 18.4831 7.15622 18.2998 7.33955L13.3998 12.2396L18.2998 17.1396C18.4831 17.3229 18.5748 17.5562 18.5748 17.8396C18.5748 18.1229 18.4831 18.3562 18.2998 18.5396C18.1165 18.7229 17.8831 18.8146 17.5998 18.8146C17.3165 18.8146 17.0831 18.7229 16.8998 18.5396L11.9998 13.6396Z' fill='black'/%3E%3C/g%3E%3C/svg%3E%0A");
                        background-size: 1.4rem;
                        background-repeat: no-repeat;
                        background-position: center;
                    }
                }

                @include breakpoint(small only) {
                    width: 18rem;
                }

                &.--right {
                    right: 3rem;
                    left: auto;

                    .--close {
                        right: auto;
                        left: -1.2rem;
                    }
                }

                &.--bottom {
                    top: auto;
                    bottom: 2.5rem;

                    .--close {
                        top: -1rem;
                    }
                }

                .spot__holder {
                    .image__holder {
                        display: flex;
                        justify-content: center;
                    }

                    .product__holder, .content__holder {
                        display: flex;
                        flex-direction: column;
                        gap: .2rem;
                        height: 100%;

                        @include breakpoint(medium) {
                            gap: $grid__padding;

                            &:is(.content__holder) {
                                gap: 0;
                            }
                        }

                        &:is(.content__holder) {
                            margin: $grid__padding 0 0;
                        }

                        ._manufacturer {
                            background-repeat: no-repeat;
                            background-size: contain;
                            height: 1.8rem;
                        }

                        h2 {
                            font-size: 1.4rem;
                            line-height: 1.8rem;
                            flex: 1;
                        }

                        h4 {
                            font-size: 2rem;
                            line-height: 2rem;
                            font-weight: 700;
                        }

                        .price__holder {
                            @include price-component();
                        }
                    }

                    .--button {
                        margin: 1rem 0 0;
                    }

                    > a:not(.--button) {
                        font-size: 1.4rem;
                        text-decoration: underline;
                        color: color(Base, PrimaryColor);
                    }
                }
            }
        }

        > .--button {
            position: absolute;
            z-index: 3;
            bottom: 2rem;
            right: 2rem;
        }
    }
}
