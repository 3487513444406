.seo__widget {
    font-size: 1.6rem;

    h1, h2, h3, h4 {
        font-size: 2.4rem;
        font-weight: 700;
        line-height: 3rem;
        margin: $grid__padding 0;
    }

    h3 {
        font-size: 2rem;
        line-height: 2.2rem;
    }

    h4 {
        font-size: 1.4rem;
    }

    p {
        font-size: 1.6rem;
        line-height: 2.6rem;
        margin: $grid__padding 0;
    }

    p, ul li, ol li, strong {
        a {
            text-decoration: underline;
        }
    }

    > a {
        text-decoration: underline;
    }

    ul, ol {
        list-style: disc outside;
        margin: $grid__padding 0 $grid__padding 2rem;

        &:is(ol) {
            list-style: decimal outside;
        }

        li {
            font-size: 1.6rem;
            line-height: 2.6rem;
            padding: .3rem 0;
        }
    }

    .ql-align-center {
        text-align: center;
    }

    .ql-align-right {
        text-align: right;
    }

    .ql-align-justify {
        text-align: justify;
    }

    em {
        font-style: italic;
    }

    u {
        text-decoration: underline;
    }

    hr {
        border-top: .1rem solid color(Gray, Gray-300);
        margin: $grid__padding 0;
    }

    .--spacer {
        font-size: 1.6rem;
        line-height: 2.6rem;
        display: block;
        width: 100%;
    }

    table {
        width: 100%;
        margin: 0 0 $grid__padding;

        tr {
            &:nth-child(even) {
                background: color(Gray, Gray-200);
            }

            th, td {
                text-align: left;
                border: .1rem solid color(Gray, Gray-300);
                border-collapse: collapse;
                padding: .4rem .8rem;
                font-size: 1.6rem;
                line-height: 2.6rem;

                &:is(th) {
                    font-weight: 600;
                }
            }
        }
    }
}
