.navigation__container {
    background-color: color(Base, PrimaryColor);

    nav {
        margin: 0 -1.2rem;
        flex-wrap: nowrap;
        overflow-y: auto;

        &::-webkit-scrollbar {
            height: .3rem;
        }

        &::-webkit-scrollbar-thumb {
            background-color: color(Base, White);
            border-radius: .15rem;
        }

        > ul {
            display: flex;
            gap: 1.2rem;

            > li {
                > a, > span {
                    display: block;
                    font-size: clamp(1.2rem, 1.057rem + 0.286vw, 1.4rem);
                    line-height: clamp(1.8rem, 1.514rem + 0.571vw, 2.2rem);
                    color: color(Base, White);
                    font-weight: 700;
                    padding: 1.2rem;
                }

                ._dropdown {
                    position: absolute;
                    background-color: color(Base, White);
                    left: 0;
                    right: 0;
                    display: none;

                    .--loading {
                        padding: 2.4rem;
                    }

                    &.active {
                        display: block;
                    }

                    .grid-x.grid-padding-x {
                        > div:first-of-type {
                            background-color: color(Gray, Gray-200);
                            border-right: .1rem solid color(Gray, Gray-300);

                            a, span, strong {
                                line-height: 3rem;
                            }
                        }
                    }

                    ul {
                        margin: 2.4rem .8rem;

                        li {
                            strong, strong * {
                                font-weight: 600;
                            }

                            a, span, strong {
                                line-height: 2.6rem;
                                font-size: 1.4rem;

                                &:is(a):hover {
                                    text-decoration: underline;
                                }
                            }

                            &:first-of-type {
                                a, span, strong {
                                    font-weight: 600;
                                }
                            }
                        }

                        &._semi-bold {
                            li {
                                > a, > span {
                                    font-weight: 600;
                                }
                            }
                        }
                    }
                }

                @include hover {
                    > a, > span {
                        background-color: color(Base, White);
                        color: color(Base, Black);
                    }
                }
            }
        }
    }
}
